import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { Dialog } from '@syncfusion/ej2-popups';
import { DateTimePicker, DateTimePickerModel } from '@syncfusion/ej2-calendars';
import {
    AgendaService,
    DayService,
    DragAndDropService,
    MonthService,
    ResizeService,
    WeekService,
    WorkWeekService,
    EventSettingsModel,
    PopupOpenEventArgs,
    ScheduleComponent,
    EJ2Instance,
    ActionEventArgs,
    TimeScaleModel,
    PopupCloseEventArgs,
} from '@syncfusion/ej2-angular-schedule';

import { loadCldr } from '@syncfusion/ej2-base';
import { TranslateService } from '@ngx-translate/core';
import { SchedulerResourceEvent } from 'dm-src/models/scheduler-resource-event';
import moment from 'moment';
import { SupplierResourcesService } from 'dm-src/app/modules/supplier-resources/supplier-resources.service';
import { skip, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { SupplierResourceUser } from 'dm-src/types/supplier-resource-user';
import { AppSettings } from 'shared';
import { SchedulerBaseComponent } from '../shared/scheduler-base/scheduler-base.component';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-supplier-resources',
    templateUrl: './supplier-resources.component.html',
    styleUrls: ['./supplier-resources.component.scss'],
    providers: [
        DayService,
        WeekService,
        WorkWeekService,
        MonthService,
        AgendaService,
        ResizeService,
        DragAndDropService,
    ],
})
export class SupplierResourcesComponent
    extends SchedulerBaseComponent
    implements OnInit, OnDestroy
{
    @ViewChild('scheduler', { static: true })
    public scheduler: ScheduleComponent;
    public views: Array<string> = ['Day', 'Week', 'WorkWeek', 'Month'];
    public currentSupplierID: string;
    public eventSettings: EventSettingsModel = {
        dataSource: [],
    };
    public skipApiCall: boolean;
    public timeScale: TimeScaleModel = {
        enable: true,
        interval: 60,
        slotCount: 1,
    };
    public selectedResourceSuppliers: SupplierResourceUser[];

    constructor(
        private _translateService: TranslateService,
        private _supplierResourcesService: SupplierResourcesService,
        protected httpClient: HttpClient
    ) {
        super(httpClient);
    }

    ngOnInit(): void {
        this.skipApiCall = false;
        super.ngOnInit();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public onTimeCellClicked(args: PopupOpenEventArgs): void {
        const dialogObj: Dialog = (args.element as EJ2Instance)
            .ej2_instances[0] as Dialog;
        if (this._supplierResourcesService.currentSupplierRegionID === null) {
            dialogObj.hide();
            return;
        }
        if (args.type === 'QuickInfo' || args.type === 'EditEventInfo') {
            dialogObj.hide();
            const currentAction = args.target.classList.contains('e-work-cells')
                ? 'Add'
                : 'Save';
            this.scheduler.openEditor(args.data, currentAction);
        } else if (args.type === 'Editor') {
            const event = args.data as SchedulerResourceEvent;
            this.selectedResourceSuppliers = event.ResourceUsers;
            const datePickerOptions: DateTimePickerModel = {
                format: 'yyyy.MM.dd. HH:mm',
                step: 60,
                strictMode: true,
            };
            const startElement: HTMLInputElement = args.element.querySelector(
                '#StartTime'
            ) as HTMLInputElement;
            if (!startElement.classList.contains('e-datetimepicker')) {
                datePickerOptions.value = new Date(startElement.value) || new Date();
                new DateTimePicker(datePickerOptions, startElement);
            }
            const endElement: HTMLInputElement = args.element.querySelector(
                '#EndTime'
            ) as HTMLInputElement;
            if (!endElement.classList.contains('e-datetimepicker')) {
                datePickerOptions.value = new Date(endElement.value) || new Date();
                new DateTimePicker(datePickerOptions, endElement);
            }
            const reservedSlot: HTMLElement = args.element.querySelector('#ReservedSlot');
            const reservedSlotValue: HTMLInputElement =
                args.element.querySelector('#ReservedSlotValue');

            if (event.ReservedSlot !== undefined) {
                const slotNumber = event.ReservedSlot.toString();
                reservedSlot.innerText = slotNumber;
                reservedSlotValue.value = slotNumber;
            }
        }
    }

    public onSchedulerAction(args: ActionEventArgs): void {
        if (
            this.skipApiCall ||
            this._supplierResourcesService.currentSupplierRegionID === null
        ) {
            return;
        }

        let event: SchedulerResourceEvent;
        let eventDurationHours: number;

        switch (args.requestType) {
            case 'eventChange':
                event = args.data as SchedulerResourceEvent;
                eventDurationHours = moment(event.EndTime).diff(
                    moment(event.StartTime),
                    'hours'
                );

                args.cancel = true;
                const isEventValid = this.validateEventOverlap(event);
                if (isEventValid) {
                    if (eventDurationHours > 1) {
                        this.onEventDeleted(event);
                        this.createEvents(event);
                    } else {
                        this.onEventUpdated(event);
                    }
                }
                break;

            case 'eventCreate':
                event = args.data[0] as SchedulerResourceEvent;
                eventDurationHours = moment(event.EndTime).diff(
                    moment(event.StartTime),
                    'hours'
                );
                event.Subject = this.createEventSubject(event);
                if (eventDurationHours > 1) {
                    args.cancel = true;
                    this.createEvents(event);
                }
                break;
        }
    }

    public onAfterSchedulerAction(args: ActionEventArgs) {
        if (this.skipApiCall) {
            this.skipApiCall = false;
            return;
        }

        const events = args.data as SchedulerResourceEvent[];

        switch (args.requestType) {
            case 'eventCreated':
                this.onEventCreated(events);
                break;
            case 'eventRemoved':
                this._supplierResourcesService
                    .deleteSchedulerEvent(events[0].EventID)
                    .subscribe();
                break;
        }
    }

    public onSchedulerCreated(): void {
        this.scheduler.firstDayOfWeek = 1;
        this._supplierResourcesService.schedulerEvents
            .pipe(skip(1))
            .pipe(takeUntil(this._destroy$))
            .subscribe((events) => {
                if (events.length) {
                    this.skipApiCall = true;
                    this.scheduler.deleteEvent(
                        this.scheduler.eventsData as { [key: string]: Object }[]
                    );
                    this.skipApiCall = true;
                    events = events.map((event) => {
                        event.Subject = this.createEventSubject(event);
                        return event;
                    });
                    this.scheduler.addEvent(events);
                }
            });
    }

    public onPopupClosed(args: PopupCloseEventArgs): void {
        const action = this.scheduler.currentAction;
        if (args.type === 'Editor') {
            if ((action === 'Add' || action === 'Save') && args.data !== undefined) {
                const event = args.data as SchedulerResourceEvent;
                // tslint:disable-next-line:radix
                if (
                    parseInt(String(event.AvailableSlot)) <
                    parseInt(String(event.ReservedSlot))
                ) {
                    this._supplierResourcesService.setAvailableSlotInvalid(true);
                    args.cancel = true;
                } else {
                    this._supplierResourcesService.setAvailableSlotInvalid(false);
                }
            } else {
                this._supplierResourcesService.setAvailableSlotInvalid(false);
            }
        }
    }

    private createEvents(event: SchedulerResourceEvent): void {
        let currentDate = event.StartTime;
        const eventEndTime = event.EndTime;
        const eventsToCreate = [];
        let resourceId = event.Id;
        while (currentDate < eventEndTime) {
            const newEvent = { ...event };
            newEvent.Id = resourceId++;
            newEvent.StartTime = new Date(currentDate);
            newEvent.EndTime = moment(currentDate).add(1, 'hours').toDate();
            newEvent.Subject = this.createEventSubject(newEvent);
            eventsToCreate.push(newEvent);
            currentDate = newEvent.EndTime;
        }
        this.skipApiCall = false;
        this.scheduler.addEvent(eventsToCreate);
    }

    private onEventCreated(events: SchedulerResourceEvent[]): void {
        this._supplierResourcesService
            .createSchedulerEvents(events)
            .subscribe((response) => {
                this.skipApiCall = true;
                if (response.status === 200) {
                    events.forEach((event, index) => {
                        event.EventID = response.body[index];
                        event.Subject = this.createEventSubject(event);
                        this.scheduler.saveEvent(event);
                    });
                }
            });
    }

    private onEventUpdated(event: SchedulerResourceEvent): void {
        this._supplierResourcesService.updateSchedulerEvent(event);
        this.skipApiCall = true;
        this.scheduler.deleteEvent(event.Id);
        event.Subject = this.createEventSubject(event);
        this.skipApiCall = true;
        this.scheduler.addEvent(event);
    }

    private onEventDeleted(event: SchedulerResourceEvent): void {
        this.scheduler.deleteEvent(event.Id);
    }

    private validateEventOverlap(event: SchedulerResourceEvent): boolean {
        let timeslot = event.StartTime;
        let isValid = true;
        const events = this.scheduler.eventsData as SchedulerResourceEvent[];

        while (timeslot < event.EndTime) {
            if (
                events.some(
                    (x) =>
                        x.EventID !== event.EventID &&
                        x.StartTime.toString() === timeslot.toString()
                )
            ) {
                isValid = false;
                break;
            }
            timeslot = moment(timeslot).add(1, 'hours').toDate();
        }

        return isValid;
    }

    private createEventSubject(event: SchedulerResourceEvent): string {
        return (
            this._translateService.instant('supplier-resources.slots') +
            ': ' +
            event.AvailableSlot +
            ', ' +
            this._translateService.instant('supplier-resources.reserved-slots') +
            ': ' +
            event.ReservedSlot
        );
    }
}
