import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SendBirdChatChannel } from '../../../../shared/src/lib/models/sendbird-chat-channel';
declare global {
    interface Window {
        ReactNativeWebView: any;
    }
}

export interface PostMessageResponse {
    functionName: string;
    result: any;
    isSuccess: boolean;
}

export interface MessageEventCommandDto {
    command: string;
    data?: any;
}

export interface GeolocationPosition {
    readonly coords: GeolocationCoordinates;
    readonly timestamp: number;
}

export interface GeolocationCoordinates {
    readonly accuracy: number;
    readonly altitude: number | null;
    readonly altitudeAccuracy: number | null;
    readonly heading: number | null;
    readonly latitude: number;
    readonly longitude: number;
    readonly speed: number | null;
}

@Injectable({
    providedIn: 'root',
})
export class IosAndAndroidNatvieFunctionsService {
    public osType = 'Unknown';
    currentPostMessage = new BehaviorSubject<PostMessageResponse>(
        {} as PostMessageResponse
    );
    public currentLatLonData = new BehaviorSubject<any>({});
    private WrapperFunction: any;

    constructor() {
        this.osType = this.getMobileOperatingSystem();
    }

    public setCurrentPostMessage(postMessageResponse: string): void {
        try {
            this.currentPostMessage.next(
                JSON.parse(postMessageResponse) as PostMessageResponse
            );
        } catch {
            return;
        }
    }

    public getCurrentPostMessage(): BehaviorSubject<PostMessageResponse> {
        return this.currentPostMessage;
    }

    showToast(message: string): void {
        if (window.ReactNativeWebView) {
            this.sendToPostMessageBridge({
                command: 'OPEN_TOOLTIP',
                data: message,
            });
        } else {
            if (this.osType === 'iOS') {
                (window as any).webkit.messageHandlers.showToast.postMessage(message);
            } else if (this.osType === 'Android') {
                // @ts-ignore
                WrapperFunction.showToast(message);
            }
        }
    }

    showDialog(message: string): void {
        if (window.ReactNativeWebView) {
            this.sendToPostMessageBridge({
                command: 'OPEN_DIALOG',
                data: message,
            });
        } else {
            if (this.osType === 'iOS') {
                (window as any).webkit.messageHandlers.showDialog.postMessage(message);
            } else if (this.osType === 'Android') {
                // @ts-ignore
                WrapperFunction.showDialog(message);
            }
        }
    }

    getFrontendVersion(versionNumber: string): void {
        if (window.ReactNativeWebView) {
            this.sendToPostMessageBridge({
                command: 'GET_FRONTEND_VERSION',
            });
        } else {
            if (this.osType === 'iOS') {
                (window as any).webkit.messageHandlers.getFrontendVersion.postMessage(
                    versionNumber
                );
            } else if (this.osType === 'Android') {
                // @ts-ignore
                WrapperFunction.getFrontendVersion(versionNumber);
            }
        }
    }

    openBarcodeReader(CameraId = 0, Identifier = ''): void {
        if (window.ReactNativeWebView) {
            this.sendToPostMessageBridge({
                command: 'BARCODE_READING_START',
            });
        } else {
            if (this.osType === 'iOS') {
                (window as any).webkit.messageHandlers.openBarcodeReader.postMessage({
                    cameraId: CameraId,
                    identifier: Identifier,
                });
            } else if (this.osType === 'Android') {
                // @ts-ignore
                WrapperFunction.openBarcodeReader(CameraId, Identifier);
            }
        }
    }

    selectFrontendVersion(title: string): void {
        if (window.ReactNativeWebView) {
            this.sendToPostMessageBridge({
                command: 'WEBVIEW_URL',
            });
        } else {
            if (this.osType === 'iOS') {
                (window as any).webkit.messageHandlers.selectFrontendVersion.postMessage(
                    title
                );
            } else if (this.osType === 'Android') {
                // @ts-ignore
                WrapperFunction.selectFrontendVersion(title);
            }
        }
    }
    setFrtontendURl(url: string): void {
        if (window.ReactNativeWebView) {
            this.sendToPostMessageBridge({
                command: 'WEBVIEW_URL',
                data: url,
            });
        }
    }

    takeAPicture(): void {
        if (window.ReactNativeWebView) {
            this.sendToPostMessageBridge({
                command: 'CAMERA_START',
            });
        } else {
            if (this.osType === 'iOS') {
                (window as any).webkit?.messageHandlers?.takeAPicture?.postMessage('');
            } else if (this.osType === 'Android') {
                // @ts-ignore
                WrapperFunction.takeAPicture();
            }
        }
    }

    openSumUp(
        amount: number,
        currency: string,
        foreignTxId: string,
        affiliateKey: string,
        orderCode: string
    ): void {
        const description = 'AUTOMATA/' + orderCode + '/PAY';
        if (window.ReactNativeWebView) {
            this.sendToPostMessageBridge({
                command: 'OPEN_SUMUP',
                data: {
                    amount,
                    currency,
                    foreignTxId,
                    affiliateKey,
                    description,
                },
            });
        } else {
            if (this.osType === 'iOS') {
                (window as any).webkit.messageHandlers.openSumUp.postMessage({
                    amount,
                    currency,
                    foreignTxId,
                    affiliateKey,
                    description,
                });
            } else if (this.osType === 'Android') {
                // @ts-ignore
                WrapperFunction.OpenSumUp(
                    amount,
                    currency,
                    foreignTxId,
                    affiliateKey,
                    description
                );
            }
        }
    }

    getCurrentLocation(taskID = '', sender = ''): string {
        if (window.ReactNativeWebView) {
            this.sendToPostMessageBridge({
                command: 'GET_LAT_LON',
                data: {
                    taskID,
                    sender,
                },
            });
        } else {
            if (this.osType.toLowerCase() === 'ios') {
                // @ts-ignore
                (window as any).webkit.messageHandlers.getLocation.postMessage('');

                return localStorage.getItem('last_location');
            } else if (this.osType.toLowerCase() === 'android') {
                // @ts-ignore
                return WrapperFunction.getCurrentLocation();
            }
        }
    }

    newFrontendUrlChoose(): void {
        if (window.ReactNativeWebView) {
            this.sendToPostMessageBridge({
                command: 'NEW_FRONTEND_URL_CHOOSE',
            });
        }
    }

    toggleSettings(isShow: boolean): void {
        this.sendToPostMessageBridge({
            command: 'TOGGLE_SETTINGS',
            data: {
                show: isShow,
            },
        });
    }

    openSendbird(params: SendBirdChatChannel): void {
      const config = {
        command: 'OPEN_SENDBIRD_CHANNEL',
        data: {
          userId: params.userId,
          channelUrl: params.channelUrl,
          nickname: params.nickName,
        },
      };
      console.log(config);
        if (window.ReactNativeWebView) {
            this.sendToPostMessageBridge(config);
        }
    }

    latLonStart(data: any): void {
        if (window.ReactNativeWebView) {
            this.sendToPostMessageBridge({
                command: 'LAT_LON_START',
                data: data,
            });
        }
    }
    latLonStop(): void {
        if (window.ReactNativeWebView) {
            this.sendToPostMessageBridge({
                command: 'LAT_LON_STOP',
            });
        }
    }

    setKeyValue(key: string, value: any): void {
        if (window.ReactNativeWebView) {
            this.sendToPostMessageBridge({
                command: 'SET_KEYVALUE',
                data: {
                    key: key,
                    value: value,
                },
            });
        }
    }
    getKeyValue(key: string): void {
        if (window.ReactNativeWebView) {
            this.sendToPostMessageBridge({
                command: 'GET_KEYVALUE',
                data: {
                    key: key,
                },
            });
        }
    }

    oneSignalSetTag(tagKey: string, tagValue: any): void {
        if (window.ReactNativeWebView) {
            this.sendToPostMessageBridge({
                command: 'ONESGINAL_SET_TAG',
                data: {
                    tagKey: tagKey,
                    tagValue: tagValue,
                },
            });
        }
    }
    oneSignalSetEmail(email: string): void {
        if (window.ReactNativeWebView) {
            this.sendToPostMessageBridge({
                command: 'ONESIGNAL_SET_EMAIL',
                data: {
                    email: email,
                },
            });
        }
    }

    getPermissionRequest(roleName: string): void {
        // @ts-ignore
        return WrapperFunction.getPermissionRequest(roleName);
    }

    public getMobileOperatingSystem(): string {
        const userAgent = navigator.userAgent || navigator.vendor;

        // Windows Phone must come first because its UA also contains "Android"

        if (/windows phone/i.test(userAgent)) {
            return 'Windows Phone';
        }

        if (/android/i.test(userAgent)) {
            return 'Android';
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (this.iOS()) {
            return 'iOS';
        }
        return 'unknown';
    }

    iOS(): boolean {
        return (
            [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod',
            ].includes(navigator.platform) ||
            // iPad on iOS 13 detection
            (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
        );
    }

    private sendToPostMessageBridge(
        messageEventCommandDto: MessageEventCommandDto
    ): Observable<PostMessageResponse> {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify(messageEventCommandDto));
            return this.currentPostMessage.pipe(
                filter(
                    (postMessageResponse: PostMessageResponse) =>
                        postMessageResponse.functionName ===
                        messageEventCommandDto.command
                )
            );
        } else {
            console.info(
                `The window.ReactNativeWebView object is only available from the device or android/iOS simulator! functionName: ${messageEventCommandDto.command}`
            );
            return of({
                functionName: messageEventCommandDto.command,
                result: '-1',
                isSuccess: false,
            } as PostMessageResponse);
        }
    }
}
