import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { skip, takeUntil } from 'rxjs/operators';
import { CollectingItemsService } from 'dm-src/app/modules/collecting/collecting-items.service';
import { ReplaySubject } from 'rxjs';
import { ICreateOrderItem, UnitType } from 'shared';
import { CollectingStatesService } from '../../collecting-states.service';

@Component({
    selector: 'app-create-item-modal',
    templateUrl: './create-item-modal.component.html',
    styleUrls: ['./create-item-modal.component.css'],
})
export class CreateItemModalComponent implements OnInit, OnDestroy {
    private _destroy$: ReplaySubject<boolean>;
    @ViewChild('createOrderItemModal', { static: false })
    private _createOrderItemModal: TemplateRef<any>;
    public createOrderItemForm: UntypedFormGroup;
    public isSubmitted: boolean;
    public unitTypes = Object.keys(UnitType);
    public unitTypeLabels = Object.values(UnitType);

    public get fields() {
        return this.createOrderItemForm.controls;
    }

    constructor(
        private _modalService: NgbModal,
        private _formBuilder: UntypedFormBuilder,
        private _orderItemsService: CollectingItemsService,
        private _states: CollectingStatesService
    ) {
        this._destroy$ = new ReplaySubject<boolean>(1);
        this.isSubmitted = false;
    }

    ngOnInit(): void {
        this.createOrderItemForm = this._formBuilder.group({
            name: [null, Validators.required],
            quantity: [null, Validators.required],
            unit: [null, Validators.required],
            unitPrice: [null, Validators.required],
        });
        this._states.isCreateItemModalVisible
            .pipe(skip(1))
            .pipe(takeUntil(this._destroy$))
            .subscribe((isVisible) => {
                if (isVisible.valueOf()) {
                    this._modalService.open(this._createOrderItemModal, {
                        windowClass: 'modal-holder',
                        centered: true,
                        size: 'sm',
                    });
                } else {
                    this._modalService.dismissAll();
                }
            });
    }

    public onSubmit() {
        this.isSubmitted = true;

        if (this.createOrderItemForm.invalid) {
            return;
        }

        const orderItemDTO = this.createOrderItemForm.value as ICreateOrderItem;

        this._orderItemsService.createOrderItem(orderItemDTO);
        this._states.setCreateItemModalVisible(false);
        this.isSubmitted = false;
        this._states.isCollectionReady = false;
    }

    ngOnDestroy(): void {
        this._destroy$.next(true);
        this._destroy$.complete();
    }
}
