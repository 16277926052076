import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SchedulerEvent } from 'dm-src/models/scheduler-event';
import {
    ISuppliersService,
    SUPPLIERS_SERVICE_IMPL,
} from 'dm-src/services/suppliers/isuppliers-service';
import { SupplierWorktime } from 'dm-src/models/supplier-worktime';
import { SupplierRegion } from 'dm-src/models/supplier-region';
import { AUTH_SERVICE_IMPL, IAuthenticationService } from 'shared';
import { TranslateService } from '@ngx-translate/core';
import { HttpResponse } from '@angular/common/http';
import {
    ISupplierResourcesService,
    SUPPLIER_RESOURCES_SERVICE_IMPL,
} from 'dm-src/services/suppliers/isupplier-resources-service';
import { SchedulerTimeslot } from 'dm-src/types/scheduler-timeslot';
import { CreateSupplierWorktimeResponse } from 'dm-src/dtos/create-supplier-worktime-response';
import { UserRole } from 'shared';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class MyWorktimesService {
    private _currentSupplierID: string;
    private _infobarMessage: BehaviorSubject<string>;
    private _currentSupplierRegion: BehaviorSubject<SupplierRegion>;
    private _schedulerEvents: BehaviorSubject<SchedulerEvent[]>;
    private _availableTimeslots: BehaviorSubject<SchedulerTimeslot[]>;

    public get schedulerEvents(): Observable<SchedulerEvent[]> {
        return this._schedulerEvents.asObservable();
    }

    public get availableTimeslots(): Observable<SchedulerTimeslot[]> {
        return this._availableTimeslots.asObservable();
    }

    public get infobarMessage(): string {
        return this._infobarMessage.getValue();
    }

    public get currentAvailableTimeslots(): SchedulerTimeslot[] {
        return this._availableTimeslots.getValue();
    }

    public get currentSupplierRegion(): SupplierRegion {
        return this._currentSupplierRegion.getValue();
    }

    public canUserRemoveEveryWorktime: boolean;

    constructor(
        @Inject(SUPPLIERS_SERVICE_IMPL)
        private _suppliersService: ISuppliersService,
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService,
        @Inject(SUPPLIER_RESOURCES_SERVICE_IMPL)
        private _supplierResourcesService: ISupplierResourcesService,
        private snackBar: MatSnackBar,
        private _translateService: TranslateService,
    ) {
        this._schedulerEvents = new BehaviorSubject<SchedulerEvent[]>([]);
        this._availableTimeslots = new BehaviorSubject<SchedulerTimeslot[]>([]);
        this._currentSupplierRegion = new BehaviorSubject<SupplierRegion>(null);
        this._infobarMessage = new BehaviorSubject<string>("");
        this.canUserRemoveEveryWorktime = this._authService.currentUser.hasRoles([
            UserRole.Administrator,
        ]);
    }

    public getSechedulerEvents() {
        this._suppliersService
            .getSupplierWorktimes(
                this._currentSupplierID ?? this._authService.currentUser.userID
            )
            .subscribe((response) => {
                if (response.status === 200) {
                    response.body.map((event, index) => {
                        event.Id = index;
                        event.StartTime = new Date(event.StartTime);
                        event.EndTime = new Date(event.EndTime);
                        return event;
                    });
                    this._schedulerEvents.next(response.body);
                }
            });
    }

    public createSchedulerEvent(
        event: SchedulerEvent
    ): Observable<HttpResponse<CreateSupplierWorktimeResponse>> {
        const supplierWorktime = this.getSupplierWorktime(event);
        return this._suppliersService.createSupplierWorktime(supplierWorktime);
    }

    public validateWorktime(
        newWorktime: SchedulerEvent,
        existingWorktimes: SchedulerEvent[]): boolean {

        let worktimeStart = moment(newWorktime.StartTime).toDate();
        let worktimeEnd = moment(newWorktime.EndTime).toDate();

        let validWorkTimeStart = this.validWorktimeStart(
            worktimeStart);

        if (!validWorkTimeStart) {
            this.showErrorMessage('messages.my-worktime-worktime-start-minutes-error');
            return false;
        }

        let validShiftMinutes = this.validShiftMinutes(
            worktimeStart,
            worktimeEnd);

        if (!validShiftMinutes) {
            this.showErrorMessage('messages.my-worktime-worktime-shift-minutes-error');
            return false;
        }

        let validShiftNumber = this.validShiftNumber(
            worktimeStart,
            worktimeEnd);

        if (!validShiftNumber) {
            this.showErrorMessage('messages.my-worktime-worktime-shift-number-error');
            return false;
        }

        let worktimesByDay = this.getWorktimesByDay(
            existingWorktimes,
            newWorktime);
            
        let endTimeMoments = worktimesByDay
            .filter((x) =>
                x.EndTime <= worktimeStart)
            .map((x) => moment(x.EndTime));

        let breakMinutes = this.currentSupplierRegion.breakShiftNumber * this.currentSupplierRegion.shiftMinutes;

        if (0 < endTimeMoments.length) {
            let maxEndTime = moment.max(endTimeMoments).toDate();
    
            let worktimeStartDiffMinutes = moment(worktimeStart).diff(moment(maxEndTime), 'minutes');
    
            let validWorktimeStart = breakMinutes <= worktimeStartDiffMinutes;
    
            if (!validWorktimeStart) {
                let previousWorktimes = worktimesByDay
                    .filter((x) =>
                        x.EndTime <= worktimeStart)
                    .sort(this.sortByStartTimeDesc);
    
                let validSumWorktimes = this.validateSumWorktimes(
                    previousWorktimes,
                    true); // Check work time start.
    
                if (!validSumWorktimes) {
                    this.showErrorMessage('messages.my-worktime-worktime-start-error');
                    return false;
                }
            }
        }

        let startTimeMoments = worktimesByDay
            .filter((x) =>
                worktimeEnd <= x.StartTime)
            .map((x) => moment(x.StartTime));

        if (0 < startTimeMoments.length) {
            let minStartTime = moment.min(startTimeMoments).toDate();
    
            let workTimeEndDiffMinutes = moment(minStartTime).diff(moment(worktimeEnd), 'minutes');

            let validWorktimeEnd = breakMinutes <= workTimeEndDiffMinutes;

            if (!validWorktimeEnd) {
                let nextWorktimes = worktimesByDay
                    .filter((x) =>
                        worktimeEnd <= x.StartTime)
                    .sort(this.sortByStartTime);
    
                let validSumWorktimes = this.validateSumWorktimes(
                    nextWorktimes,
                    false); // Check work time end.
    
                if (!validSumWorktimes) {
                    this.showErrorMessage('messages.my-worktime-worktime-end-error');
                    return false;
                }
            }
        }

        return true;
    }

    private validWorktimeStart(
        date: Date): boolean {
        let minutes = moment(date).minutes();
        return (minutes % 15) === 0;
    }

    private validShiftMinutes(
        dateFrom: Date,
        dateTo: Date): boolean {
        let diffMinutes = moment(dateTo).diff(moment(dateFrom), 'minutes');
        return (diffMinutes % this.currentSupplierRegion.shiftMinutes) === 0;
    }

    private validShiftNumber(
        dateFrom: Date,
        dateTo: Date): boolean {
        let diffMinutes = moment(dateTo).diff(moment(dateFrom), 'minutes');
        let shiftNumber = diffMinutes / this.currentSupplierRegion.shiftMinutes;
        return this.currentSupplierRegion.minimumShiftNumber <= shiftNumber &&
            shiftNumber <= this.currentSupplierRegion.maximumShiftNumber;
    }

    private getWorktimesByDay(
        existingWorktimes: SchedulerEvent[],
        newWorktime: SchedulerEvent): SchedulerEvent[] {
        let date = moment(newWorktime.StartTime).toDate();
        
        let dayStart = moment(date)
            .set({ hour: 0, minute: 0, second: 0 })
            .toDate();

        let dayEnd = moment(date)
            .set({ hour: 23, minute: 59, second: 59 })
            .toDate();

        return existingWorktimes
            .filter((x) =>
                dayStart <= x.StartTime &&
                x.EndTime <= dayEnd &&
                x.EventID !== newWorktime.EventID)
            .sort(this.sortByStartTime);
    }
    
    private sortByStartTime = (aTime, bTime) => {
        return aTime.StartTime - bTime.StartTime;
    };

    private sortByStartTimeDesc = (aTime, bTime) => {
        return bTime.StartTime - aTime.StartTime;
    };

    private validateSumWorktimes(
        existingWorktimes: SchedulerEvent[],
        checkEventStart: boolean): boolean {
        let sumMinutes = 0;

        let maxBlockMinutes = this.currentSupplierRegion.maximumShiftNumber * this.currentSupplierRegion.shiftMinutes;

        let isValid = true;

        existingWorktimes.forEach((item, index) => {
            if (index === 0) {
                sumMinutes += moment(item.EndTime).diff(moment(item.StartTime), 'minutes');
            }

            if (maxBlockMinutes <= sumMinutes) {
                isValid = false;
                return;
            }

            if (index < existingWorktimes.length - 1) {
                let currentEventTime = checkEventStart ?
                    moment(existingWorktimes[index].StartTime).toDate() :
                    moment(existingWorktimes[index].EndTime).toDate();

                let nextEventStartTime = moment(existingWorktimes[index + 1].StartTime).toDate();
                let nextEventEndTime = moment(existingWorktimes[index + 1].EndTime).toDate();

                let nextEventTime = checkEventStart ? nextEventEndTime : nextEventStartTime;

                let isSame = moment(nextEventTime).diff(currentEventTime, 'minutes') === 0;

                if (isSame) {
                    sumMinutes += moment(nextEventEndTime).diff(moment(nextEventStartTime), 'minutes');

                    if (maxBlockMinutes <= sumMinutes) {
                        isValid = false;
                        return;
                    }

                } else {
                    isValid = true;
                    return;
                }
            }
        });

        return isValid;
    }

    private showErrorMessage(
        messageKey: string): void {
        this.snackBar.open(
            this._translateService.instant(messageKey),
            null,
            {
                verticalPosition: 'top',
                horizontalPosition: 'center',
                duration: 12000,
                panelClass: ['error-snackbar']
            }
        );
    }

    public updateSchedulerEvent(event: SchedulerEvent) {
        const supplierWorktime = this.getSupplierWorktime(event);

        return this._suppliersService.updateSupplierWorktime(supplierWorktime);
    }

    public deleteSchedulerEvent(
        supplierWorktimeID: string
    ): Observable<HttpResponse<void>> {
        return this._suppliersService.deleteSupplierWorktime(supplierWorktimeID);
    }

    public getAvailableTimeSlots(): void {
        this._supplierResourcesService
            .getAvailableTimeSlots(
                this._currentSupplierID ?? this._authService.currentUser.userID
            )
            .subscribe((response) => {
                if (response.status === 200 && response.body !== null) {
                    const timeslots = response.body.map((timeslot) => {
                        timeslot.slotStart = new Date(timeslot.slotStart);
                        timeslot.slotEnd = new Date(timeslot.slotEnd);
                        return timeslot;
                    });
                    this._availableTimeslots.next(timeslots);
                }
            });
    }

    public getSupplierRegion(): void {
        this._supplierResourcesService
            .getSupplierRegion(
                this._currentSupplierID ?? this._authService.currentUser.userID
            )
            .subscribe((response) => {
                if (response.status === 200 && response.body !== null) {
                    const supplierRegion = new SupplierRegion();
                    supplierRegion.supplierRegionID = response.body.supplierRegionID;
                    supplierRegion.regionName = response.body.regionName;
                    supplierRegion.breakShiftNumber = response.body.breakShiftNumber;
                    supplierRegion.maximumShiftNumber = response.body.maximumShiftNumber;
                    supplierRegion.minimumShiftNumber = response.body.minimumShiftNumber;
                    supplierRegion.shiftMinutes = response.body.shiftMinutes;
                    this._currentSupplierRegion.next(supplierRegion);

                    this._translateService
                        .get('my-worktime.info-bar',
                            {
                                breakShiftNumber: this.currentSupplierRegion.breakShiftNumber,
                                minimumShiftNumber: this.currentSupplierRegion.minimumShiftNumber,
                                maximumShiftNumber: this.currentSupplierRegion.maximumShiftNumber,
                                shiftMinutes: this.currentSupplierRegion.shiftMinutes,
                            })
                        .subscribe((text) => {
                            this._infobarMessage.next(text);
                        });
                }
            });
    }

    public onSupplierFilterChanged(value: string) {
        this._currentSupplierID = value;
        this.getAvailableTimeSlots();
        this.getSupplierRegion();
        this.getSechedulerEvents();
    }

    public reset() {
        this._currentSupplierID = null;
    }

    private getSupplierWorktime(event: SchedulerEvent): SupplierWorktime {
        const supplierWorktime = new SupplierWorktime();
        supplierWorktime.supplierID =
            this._currentSupplierID ?? this._authService.currentUser.userID;
        if (event.EventID) {
            supplierWorktime.supplierWorktimeID = event.EventID;
        }
        supplierWorktime.worktimeStart = event.StartTime;
        supplierWorktime.worktimeEnd = event.EndTime;

        return supplierWorktime;
    }
}
