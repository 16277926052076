import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UpdateService {
    constructor(public updates: SwUpdate) {
        if (updates.isEnabled) {
            interval(1000 * 600).subscribe(() => updates.checkForUpdate().then(() => {}));
        }
    }

    public checkForUpdates(): void {
        this.updates.versionUpdates.subscribe((event) => {
            this.promptUser();
        });
    }

    private promptUser(): void {
        this.updates.activateUpdate().then(() => console.log('Update activated'));
    }
}
