import { Component, Input, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ISelectOption } from '../../../types/i-select-option';
import { TableColumn } from '../../../types/table-column';
import {
    AUTH_SERVICE_IMPL,
    IAuthenticationService,
} from '../../../services/authentication/iauthentication.service';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

@Component({
    selector: 'lib-visible-column-dropdown',
    templateUrl: './visible-column-dropdown.component.html',
    styleUrls: ['./visible-column-dropdown.component.scss'],
})
export class VisibleColumnDropdownComponent implements OnInit {
    columnsControl = new UntypedFormControl();
    @Input() columns: TableColumn[];
    @Input() section: string;
    @Output() onColumnSelected = new EventEmitter<ISelectOption[]>();
    public selectedColumns: ISelectOption[];
    public availableColumns: ISelectOption[];

    constructor(
        private _translateService: TranslateService,
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService
    ) {}

    public setColumnVisibility(): void {
        setTimeout((x) => {
            this.onColumnSelected.emit(this.selectedColumns);
        }, 0);
    }

    public getUserVisibleColumns(): void {
        const visibleColumnsJSON =
            this._authService.currentUser.getUserSettingValue(
                this.section,
                'visibleColumns'
            ) || JSON.stringify(this.columns.map((x) => JSON.stringify(x.name))); //az összset szedjük ha nincs egyse

        if (visibleColumnsJSON != null && visibleColumnsJSON) {
            this.setSelectedColumns(JSON.parse(visibleColumnsJSON));
        }
    }

    ngOnInit(): void {
        this.availableColumns = this.columns.map((column) => {
            const option: ISelectOption = {
                id: JSON.stringify(column.name),
                // ToDo: instant method does not ork after page refresh for some reason
                label: this._translateService.instant(column.label),
            };
            return option;
        });
        this.getUserVisibleColumns();
        this.setColumnVisibility();
    }

    public hideAllColumn(): void {}

    toggleAllSelection(matSelect: MatSelect) {
        const isSelected: boolean = matSelect.options
            // The "Select All" item has the value 0, so find that one
            .filter((item: MatOption) => item.value === 0)
            // Get the value of the property 'selected' (this tells us whether "Select All" is selected or not)
            .map((item: MatOption) => item.selected)[0]; // Get the first element (there should only be 1 option with the value 0 in the select)

        if (isSelected) {
            matSelect.options.forEach((item: MatOption) => item.select());
        } else {
            matSelect.options.forEach((item: MatOption) => item.deselect());
        }
    }

    private setSelectedColumns(visibleColumnKeys: string[]): void {
        this.selectedColumns = visibleColumnKeys.map((key) =>
            this.availableColumns.find((x) => x.id === key?.replace('\\', ''))
        );
    }
}
