import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './modules/nav-menu/nav-menu.component';
import { OrdersModule } from './modules/orders/orders.module';
import { ConfirmRegistrationModule } from './modules/confirm-registration/confirm-registration.module';
import { LoginModule } from './modules/login/login.module';
import { AccountsModule } from './modules/accounts/accounts.module';
import { RegistrationModule } from './modules/registration/registration.module';
import { TasksModule } from './modules/tasks/tasks.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DecimalPipe, registerLocaleData } from '@angular/common';
import localeHu from '@angular/common/locales/hu';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { icons } from './app.icons';
import { NgxBootstrapIconsModule } from 'ngx-bootstrap-icons';
import { MyTasksModule } from './modules/my-tasks/my-tasks.module';
import { serviceProviders } from './app.service.config';
import { CollectingModule } from './modules/collecting/collecting.module';
import { PreloaderModule } from 'dm-src/app/modules/preloader/preloader.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppSharedModule } from 'dm-src/app/modules/shared/app-shared.module';
import { ProviderOrdersModule } from 'dm-src/app/modules/provider-orders/provider-orders.module';
import { TaskGeneratorModule } from 'dm-src/app/modules/task-generator/task-generator.module';
import { MyWorktimesModule } from 'dm-src/app/modules/my-worktimes/my-worktimes.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MyAccountModule } from 'dm-src/app/modules/my-account/my-account.module';
import { DailyReviewModule } from 'dm-src/app/modules/daily-review/daily-review.module';
import { SupplierResourcesModule } from 'dm-src/app/modules/supplier-resources/supplier-resources.module';
import { NgbDropdownModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TourPlannerModule } from './modules/tour-planner/tour-planner.module';
import { SupplierRegionsModule } from 'dm-src/app/modules/supplier-regions/supplier-regions.module';
import { InstantDeliveryModule } from './modules/instant-delivery/instant-delivery.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppSettings, SharedModule, ORDERS_SERVICE_IMPL, OrdersService } from 'shared';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { SupplierTrackingModule } from './modules/supplier-tracking/supplier-tracking.module';
import { SupplierControlModule } from './modules/supplier-control/supplier-control.module';
import { REALTIME_CLIENT_IMPL } from 'projects/shared/src/lib/services/realtime-client/irealtime-client.service';
import { RealtimeClientService } from 'projects/shared/src/lib/services/realtime-client/realtime-client.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PermissionRequestWindowModule } from './modules/permission-request-window/permission-request-window.module';
import { ErrorHandlerService } from 'projects/shared/src/lib/services/app-insights/error-handler.service';
import * as Sentry from '@sentry/angular-ivy';
import { NgxPullToRefreshModule } from 'ngx-pull-to-refresh';

registerLocaleData(localeHu, 'hu-HU');

@NgModule({
    declarations: [AppComponent, NavMenuComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        TranslateModule.forRoot({
            defaultLanguage: AppSettings.get('defaultLocale'),
            loader: {
                provide: TranslateLoader,
                useFactory: translateLoaderFactory,
                deps: [HttpClient],
            },
        }),
        SharedModule,
        MatSnackBarModule,
        NgxBootstrapIconsModule.pick(icons),
        FormsModule,
        AppSharedModule,
        NgxPullToRefreshModule,
        OrdersModule,
        ProviderOrdersModule,
        ConfirmRegistrationModule,
        LoginModule,
        AccountsModule,
        RegistrationModule,
        TasksModule,
        DashboardModule,
        MyTasksModule,
        MyWorktimesModule,
        SupplierResourcesModule,
        SupplierRegionsModule,
        MyAccountModule,
        DailyReviewModule,
        CollectingModule,
        TaskGeneratorModule,
        PreloaderModule,
        AppRoutingModule,
        TourPlannerModule,
        InstantDeliveryModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
        }),
        BrowserAnimationsModule,
        NgbDropdownModule,
        NgbModule,
        FontAwesomeModule,
        SupplierTrackingModule,
        SupplierControlModule,
        PermissionRequestWindowModule,
        Sentry.TraceModule,
    ],
    providers: [
        serviceProviders,
        {
            provide: REALTIME_CLIENT_IMPL,
            useClass: RealtimeClientService,
        },
        {
            provide: ORDERS_SERVICE_IMPL,
            useClass: OrdersService,
        },
        { provide: DecimalPipe },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        { provide: ErrorHandler, useClass: ErrorHandlerService },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

export function translateLoaderFactory(http: HttpClient): MultiTranslateHttpLoader {
    return new MultiTranslateHttpLoader(http, [
        {
            prefix: AppSettings.get('backendBaseURL') + '/translations/get/1/',
            suffix: '',
        },
        {
            prefix: AppSettings.get('backendBaseURL') + '/translations/get/5/',
            suffix: '',
        },
    ]);
}
