import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ACCOUNTS_SERVICE_IMPL, IAccountsService, MustMatch } from 'shared';
import { AnimationHelper } from 'shared';
import { TranslateService } from '@ngx-translate/core';
import { UserRegistration } from 'projects/shared/src/lib/dtos/user-registration';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
    public regForm: UntypedFormGroup;
    public isSubmitted: boolean;
    public isLoading: boolean;
    public errorMessages: string[];
    public isRegisteredSuccessfully: boolean;

    constructor(
        @Inject(ACCOUNTS_SERVICE_IMPL) private _accountService: IAccountsService,
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService
    ) {}

    ngOnInit() {
        this.isRegisteredSuccessfully = false;
        this.regForm = this._formBuilder.group(
            {
                firstName: ['', Validators.required],
                lastName: ['', Validators.required],
                email: ['', [Validators.required, Validators.email]],
                password: ['', Validators.required],
                repassword: ['', Validators.required],
                approveTermsAndConditions: [false, Validators.requiredTrue],
            },
            {
                validator: MustMatch('password', 'repassword'),
            }
        );
        this.isSubmitted = false;
    }

    public get fields() {
        return this.regForm.controls;
    }

    onSubmit() {
        this.isSubmitted = true;

        if (this.regForm.invalid) {
            return;
        }

        this.isLoading = true;

        let registrationData = new UserRegistration();
        registrationData = this.regForm.value;
        registrationData.confirmationBaseUrl =
            location.origin + '/accounts/confirm-registration';

        this._accountService
            .registerUser(this.regForm.value)
            .pipe(first())
            .subscribe(
                (success) => {
                    this.isRegisteredSuccessfully = true;
                    AnimationHelper.scrollToTop();
                },
                (error: HttpErrorResponse) => {
                    this.isLoading = false;
                    this.errorMessages = error.error
                        .split(',')
                        .map((err) =>
                            this._translateService.instant('registration.' + err)
                        );
                    AnimationHelper.scrollToTop();
                }
            );
    }
}
