/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ServiceBaseService } from 'projects/shared/src/lib/services/service-base.service';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';

 import { registerLicense } from '@syncfusion/ej2-base';
// // Register the license key
 registerLicense('Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXdfcnRXRGleUU1+V0Y=');


if (
    ServiceBaseService.getEnvironment.indexOf('prod') > -1 ||
    localStorage.getItem('isSentryLogEnabled') == '1'
) {
    Sentry.init({
        dsn: 'https://aa17e38319534df9acd53a4a18ab9233@o4504877451182080.ingest.sentry.io/4505442443853824',
        release: 'delivery-manager-V1.0',
        tracesSampleRate: 0.2,
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        //replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        //replaysOnErrorSampleRate: 1.0,
        environment: ServiceBaseService.getEnvironment,
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: [
                    'http://localhost:5009/',
                    'http://localhost:5010/',
                    'https://delivery-manager.roksh.com',
                    'https://delivery-manager.roksh.at',
                ],
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
            new Sentry.Integrations.Breadcrumbs({
                console: false,
            }),
        ],
    });
}

export function getBaseUrl() {
    return document.getElementsByTagName('base')[0].href;
}

const providers = [{ provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }];

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic(providers)
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
