export enum OrderItemState {
    Collected = 1,
    Replaced = 2,
    NotFound = 3,
    NotAvaliable = 4,
    Approved = 5,
    Declined = 6,
    RemovedByCustomer = 7,
}

export const OrderItemStateLabels: { [key: number]: string } = {
    [OrderItemState.Collected]: 'Collected',
    [OrderItemState.Replaced]: 'Replaced',
    [OrderItemState.NotFound]: 'Not Found',
    [OrderItemState.NotAvaliable]: 'Not Available',
    [OrderItemState.Approved]: 'Approved',
    [OrderItemState.Declined]: 'Declined',
    [OrderItemState.RemovedByCustomer]: 'Removed by Customer',
};

export function getOrderItemStateOptions() {
    return Object.keys(OrderItemState)
        .filter(key => !isNaN(Number(OrderItemState[key])))
        .map(key => ({ id: Number(OrderItemState[key]), name: OrderItemStateLabels[OrderItemState[key]] }));
}