<div class="container mt-lg-4">
    <div class="row">
        <div
            *appHasRoles="['picker', 'deliverer']"
            class="col-sm-12 mb-3 col-md-6 col-lg-3"
        >
            <app-dashboard-card
                [icon]="'list'"
                [title]="'navigation.my-tasks'"
                [background]="ColorClass.Info"
                [routerLink]="['/my-tasks']"
            ></app-dashboard-card>
        </div>
        <div
            *appHasRoles="['picker', 'deliverer']"
            class="col-sm-12 mb-3 col-md-6 col-lg-3"
        >
            <app-dashboard-card
                [icon]="'calendar'"
                [title]="'navigation.my-worktimes'"
                [background]="ColorClass.Warning"
                [routerLink]="['/my-worktimes']"
            ></app-dashboard-card>
        </div>
        <div *appHasRoles="['deliverer']" class="col-sm-12 mb-3 col-md-6 col-lg-3">
            <app-dashboard-card
                [icon]="'dropbox'"
                [title]="'navigation.assign-task'"
                [background]="ColorClass.Secondary"
                [textColor]="'black-50'"
                [routerLink]="['/task-generator']"
            ></app-dashboard-card>
        </div>
        <div *appHasRoles="['user']" class="col-sm-12 mb-3 col-md-6 col-lg-3">
            <app-dashboard-card
                [icon]="'user'"
                [title]="'navigation.my-account'"
                [background]="ColorClass.Primary"
                [routerLink]="['/my-account']"
            ></app-dashboard-card>
        </div>
        <div *appHasRoles="['administrator']" class="col-sm-12 mb-3 col-md-6 col-lg-3">
            <app-dashboard-card
                [icon]="'list'"
                [title]="'navigation.tasks'"
                [background]="ColorClass.Success"
                [routerLink]="['/tasks']"
            ></app-dashboard-card>
        </div>
        <div *appHasRoles="['administrator']" class="col-sm-12 mb-3 col-md-6 col-lg-3">
            <app-dashboard-card
                [icon]="'shopping-basket'"
                [title]="'navigation.orders'"
                [background]="ColorClass.Warning"
                [routerLink]="['/orders']"
            ></app-dashboard-card>
        </div>
        <div *appHasRoles="['provideradmin']" class="col-sm-12 mb-3 col-md-6 col-lg-3">
            <app-dashboard-card
                [icon]="'shopping-basket'"
                [title]="'navigation.orders'"
                [background]="ColorClass.Warning"
                [routerLink]="['/provider/orders']"
            ></app-dashboard-card>
        </div>
        <div *appHasRoles="['administrator']" class="col-sm-12 mb-3 col-md-6 col-lg-3">
            <app-dashboard-card
                [icon]="'users'"
                [title]="'navigation.accounts'"
                [background]="ColorClass.Success"
                [routerLink]="['/accounts']"
            ></app-dashboard-card>
        </div>
        <div *appHasRoles="['administrator']" class="col-sm-12 mb-3 col-md-6 col-lg-3">
            <app-dashboard-card
                [icon]="'calendar'"
                [title]="'navigation.supplier-resources'"
                [background]="ColorClass.Info"
                [routerLink]="['/supplier-resources']"
            ></app-dashboard-card>
        </div>
        <div *appHasRoles="['administrator']" class="col-sm-12 mb-3 col-md-6 col-lg-3">
            <app-dashboard-card
                [icon]="'puzzle-piece'"
                [title]="'navigation.supplier-regions'"
                [background]="ColorClass.Secondary"
                [textColor]="'black-50'"
                [routerLink]="['/supplier-regions']"
            ></app-dashboard-card>
        </div>
        <div *appHasRoles="['administrator']" class="col-sm-12 mb-3 col-md-6 col-lg-3">
            <app-dashboard-card
                [icon]="'fighter-jet'"
                [title]="'navigation.tour-planner'"
                [background]="ColorClass.Primary"
                [routerLink]="['/tour-planner']"
            ></app-dashboard-card>
        </div>
        <div
            *appHasRoles="['instantdeliverer', 'administrator']"
            class="col-sm-12 mb-3 col-md-6 col-lg-3"
        >
            <app-dashboard-card
                [icon]="'motorcycle'"
                [title]="'navigation.instant-delivery'"
                [background]="ColorClass.Secondary"
                [textColor]="'black-50'"
                [routerLink]="['/instant-delivery']"
            ></app-dashboard-card>
        </div>

        <div *appHasRoles="['administrator']" class="col-sm-12 mb-3 col-md-6 col-lg-3">
            <app-dashboard-card
                [icon]="'globe'"
                [title]="'navigation.supplier-tracking'"
                [background]="ColorClass.Warning"
                [routerLink]="['/tracking']"
            ></app-dashboard-card>
        </div>
        <div *appHasRoles="['mobiletester']" class="col-sm-12 mb-3 col-md-6 col-lg-3">
            <app-dashboard-card
                [icon]="'globe'"
                title="Frontend verzióváltás"
                [background]="ColorClass.Danger"
                (click)="handleFrontendVersion()"
            ></app-dashboard-card>
        </div>
        <div
            *appHasRoles="['suppliersupervisor']"
            class="col-sm-12 mb-3 col-md-6 col-lg-3"
        >
            <app-dashboard-card
                [textColor]="'black-50'"
                [icon]="'search'"
                title="Futár-felügyelet"
                [background]="ColorClass.Secondary"
                [routerLink]="['/supplier-control']"
            ></app-dashboard-card>
        </div>
        <div
            *appHasRoles="['picker', 'deliverer']"
            class="col-sm-12 mb-3 col-md-6 col-lg-3"
        >
            <app-dashboard-card
                [textColor]="'black-50'"
                [icon]="'eye'"
                title="navigation.daily-review"
                [background]="ColorClass.Info"
                [routerLink]="['/daily-review']"
            ></app-dashboard-card>
        </div>
        <div *appHasRoles="['user']" class="col-sm-12 mb-3 col-md-6 col-lg-3">
            <app-dashboard-card
                [textColor]="'black-50'"
                [icon]="'book'"
                title="navigation.knowledge-base"
                [background]="ColorClass.Secondary"
                [routerLink]="['/knowledge-base']"
            ></app-dashboard-card>
        </div>
    </div>
</div>
