<div class="card bg-warning text-white" *ngIf="isVisible">
    <span *ngIf="task.superOrderCode" class="badge badge-danger">
        {{ 'my-tasks.multi-store-collecting-badge' | translate }}:
        {{ task.superOrderCode }}
    </span>

    <button
        class="p-1 m-1 btn btn-success"
        *ngIf="customerChat && customerChatInfo?.userId"
        (click)="openChat()"
    >
        {{ 'common.customer-chat' | translate }}&nbsp;<i class="fa fa-envelope"></i>
    </button>
    <div class="card-header" (click)="handleCollapse()">
        <span>
            <strong>{{ task.deliveryTimeSlot }}</strong
            >,
        </span>
        <span>
            <strong>{{ task.customerZipCode }}</strong
            >,
        </span>
        <span>
            <strong>{{ task.providerName }}</strong
            >,
        </span>
        <span>
            <strong>{{ task.orderItemNumber }} SKU</strong>
        </span>
        <button
            *ngIf="checkButtonVisible"
            (click)="showCheckModal()"
            class="btn btn-success ml-2"
        >
            <span><i class="fa fa-check-circle"></i></span>
        </button>
    </div>
    <div class="card-body" [collapse]="isCollapsed" [isAnimated]="true">
        <h5 class="card-title text-center">{{ task.customerName }}</h5>
        <table class="table table-striped table-responsive table-borderless text-white">
            <tbody>
                <tr>
                    <th scope="row">{{ 'common.provider-shop' | translate }}:</th>
                    <td>
                        {{ task.providerShop }}
                    </td>
                </tr>

                <tr>
                    <th scope="row">{{ 'my-tasks.delivery-address' | translate }}:</th>
                    <td>
                        <a
                            href="http://maps.google.com/maps?q={{
                                task.deliveryAddress
                            }}"
                            class="text-white"
                            target="_blank"
                        >
                            <u>{{ task.deliveryAddress }}</u>
                        </a>
                    </td>
                </tr>
                <tr
                    [ngClass]="{
                        'bg-danger': PaymentType[task.paymentType] === 'PayOnDeliveryCash'
                    }"
                >
                    <th scope="row">{{ 'common.payment-type' | translate }}:</th>
                    <td>
                        {{
                            'payment-type.' + PaymentType[task.paymentType].toLowerCase()
                                | translate
                        }}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{{ 'common.phone' | translate }}:</th>
                    <td>
                        <a href="tel:{{ task.customerPhone }}" class="text-white">
                            <u>
                                {{ task.customerPhone }}
                            </u>
                        </a>
                    </td>
                </tr>
                <tr>
                    <th scope="row">{{ 'my-tasks.total-price' | translate }}:</th>
                    <td>
                        {{ task.customerPaidTotalPrice + task.deliveryFee }}
                        {{ 'common.currency' | translate }}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{{ 'my-tasks.order-code' | translate }}:</th>
                    <td>
                        {{ task.orderCode }}
                    </td>
                </tr>

                <!-- TODO: Estimated Delivery Time (https://weshop.atlassian.net/browse/SP-3994) -->

                <tr>
                    <th scope="row">{{ 'my-tasks.delivery-deadline' | translate }}:</th>
                    <td>
                        <span>{{ task.deliveryDeadline | amDateFormat : 'HH:mm' }}</span>
                        <span *ngIf="task.deliveryTimeSlotEnd != null">
                            -
                            {{
                                task.deliveryDeadline
                                    | amAdd : task.deliveryTimeSlotEnd : 'minutes'
                                    | amDateFormat : 'HH:mm'
                            }}
                        </span>
                    </td>
                </tr>

                <!-- TODO END -->

                <tr>
                    <th scope="row">{{ 'common.notes' | translate }}:</th>
                    <td>
                        {{ task.notes }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div [collapse]="isCollapsed" [isAnimated]="true">
        <app-wish-list-buttons
            [orderCode]="task.orderCode"
            [customerPhone]="task.customerPhone"
            [wishListOrders]="task.wishListOrders"
        ></app-wish-list-buttons>
    </div>
    <div class="card-footer" [collapse]="isCollapsed" [isAnimated]="true">
        <app-delivering-task-button [task]="task"></app-delivering-task-button>
    </div>
</div>
