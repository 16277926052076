import {
    Directive,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import {
    AUTH_SERVICE_IMPL,
    IAuthenticationService,
} from '../services/authentication/iauthentication.service';

@Directive({
    selector: '[appHasRoles]',
})
export class HasRolesDirective implements OnInit, OnDestroy {
    @Input() appHasRoles: string[];

    stop$ = new Subject();
    isVisible = false;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService
    ) {}

    ngOnInit() {
        this._authService.applicationUser.subscribe((user) => {
            if (user === null) {
                return;
            }
            const userRoles = user.roles.map((role) => role.toLowerCase());
            if (
                !user ||
                user.roles == null ||
                !user.roles.length ||
                !userRoles.filter((role) => this.appHasRoles.includes(role)).length
            ) {
                this.isVisible = false;
                this.viewContainerRef.clear();
            } else if (!this.isVisible) {
                this.isVisible = true;
                this.viewContainerRef.createEmbeddedView(this.templateRef);
            }
        });
    }

    ngOnDestroy() {
        this.stop$.next(true);
    }
}
