import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ACCOUNTS_SERVICE_IMPL, IAccountsService, MustMatch } from 'shared';
import { AUTH_SERVICE_IMPL, IAuthenticationService } from 'shared';

@Component({
    selector: 'app-my-account',
    templateUrl: './my-account.component.html',
    styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent implements OnInit {
    public regForm: UntypedFormGroup;
    public isSubmitted: boolean;
    public isLoading: boolean;
    public serializedLocalStorage: string;
    public errorMessage: string;
    public isRegisteredSuccessfully: boolean;

    constructor(
        @Inject(ACCOUNTS_SERVICE_IMPL) private _accountService: IAccountsService,
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService,
        private _formBuilder: UntypedFormBuilder,
        private _route: ActivatedRoute,
        private _router: Router
    ) {}

    ngOnInit() {
        const userData = this._authService.currentUser;

        if (userData.username === "adampetro") {
            this.serializedLocalStorage = JSON.stringify(this._authService.getUserFromStorage());
        }

        this.isRegisteredSuccessfully = false;
        this.regForm = this._formBuilder.group(
            {
                firstName: ['', Validators.required],
                lastName: ['', Validators.required],
                username: ['', Validators.required],
                email: [userData.email, [Validators.required, Validators.email]],
                password: [''],
                repassword: [''],
            },
            {
                validator: MustMatch('password', 'repassword'),
            }
        );
        this.isSubmitted = false;
    }

    public get fields() {
        return this.regForm.controls;
    }

    onSubmit() {
        this.isSubmitted = true;

        if (this.regForm.invalid) {
            return;
        }

        this.isLoading = true;

        this._accountService
            .registerUser(this.regForm.value)
            .pipe(first())
            .subscribe(
                (success) => {
                    this.isRegisteredSuccessfully = true;
                },
                (error: HttpErrorResponse) => {
                    this.isLoading = false;
                    this.errorMessage = error.error;
                }
            );
    }
}
