import {
    DeliveryTaskState,
    DeliveryTaskType,
    FormHelper,
    LogicalOperator,
    PaymentType,
    QueryOperator,
    TableColumn,
    TableColumnFilterType as FilterType,
    PaymentState,
    ConsultationType,
    BagType,
} from 'shared';

export let columns: TableColumn[] = [
    new TableColumn({
        name: ['Order.OrdererFirstName', 'Order.OrdererLastName'],
        label: 'Customer Name',
        filterOperator: QueryOperator.Contains,
        filterType: FilterType.Text,
        logicalOperator: LogicalOperator.Or,
        wordPerField: true,
    }),
    new TableColumn({
        name: ['Order.OrderCode'],
        label: 'Order Code',
        filterOperator: QueryOperator.Contains,
        filterType: FilterType.Text,
    }),
    new TableColumn({
        name: ['Order.SuperOrder.SuperOrderCode'],
        label: 'Super Order Code',
        filterOperator: QueryOperator.Contains,
        filterType: FilterType.Text,
    }),
    new TableColumn({
        name: ['DeliveryTaskTypeID'],
        label: 'Type',
        filterType: FilterType.Select,
        filterOptions: FormHelper.createSelectOptions(DeliveryTaskType),
    }),
    new TableColumn({
        name: ['DeliveryTaskStateID'],
        label: 'State',
        filterType: FilterType.Select,
        filterOptions: FormHelper.createSelectOptions(DeliveryTaskState),
    }),
    new TableColumn({
        name: ['UserID'],
        label: 'Assigned To',
        filterType: FilterType.Select,
        logicalOperator: LogicalOperator.And,
    }),
    new TableColumn({
        name: ['Order.DeliveryDate'],
        label: 'Delivery Date',
        filterType: FilterType.DatePicker,
    }),
    new TableColumn({
        name: ['supportComment'],
        label: 'Support Comment',
        filterOperator: QueryOperator.Contains,
        filterType: FilterType.Text,
    }),

    new TableColumn({
        name: ['Order.TimeSlotStartHour'],
        label: 'TimeSlot start hour',
        logicalOperator: LogicalOperator.Or,
        filterType: FilterType.Select,
    }),

    new TableColumn({
        name: ['Order.TimeSlotEndHour'],
        label: 'TimeSlot end hour',
        logicalOperator: LogicalOperator.Or,
        filterType: FilterType.Select,
    }),

    new TableColumn({
        name: ['Order.TimeSlot.HoursRange'],
        label: 'Timeslot Length',
        logicalOperator: LogicalOperator.And,
        filterType: FilterType.Select,
    }),

    new TableColumn({
        name: ['EstimatedStartDate'],
        label: 'tasks.estimated-start-date',
        filterType: FilterType.DatePicker,
    }),
    new TableColumn({
        name: ['EstimatedEndDate'],
        label: 'tasks.estimated-end-date',
        filterType: FilterType.DatePicker,
    }),
    new TableColumn({
        name: ['RealStartDate'],
        label: 'tasks.real-start-date',
        filterType: FilterType.DatePicker,
    }),
    new TableColumn({
        name: ['RealEndDate'],
        label: 'tasks.real-end-date',
        filterType: FilterType.DatePicker,
    }),
    new TableColumn({
        name: ['Order.DeliveryAddress.PostalCode'],
        label: 'Zip Code',
        filterType: FilterType.Text,
        filterOperator: QueryOperator.Contains,
    }),
    new TableColumn({
        name: ['supplierRegion'],
        label: 'SupplierRegion',
        filterType: FilterType.Text,
        filterOperator: QueryOperator.Contains,
    }),
    new TableColumn({
        name: ['Order.DeliveryAddress.AddressLine'],
        label: 'Delivery Address',
        filterOperator: QueryOperator.Contains,
        filterType: FilterType.Text,
    }),
    new TableColumn({
        name: ['Order.DeliveryAddress.CityName'],
        label: 'City name',
        filterOperator: QueryOperator.Contains,
        filterType: FilterType.Text,
    }),
    new TableColumn({
        name: ['Order.ProviderID'],
        label: 'Provider',
        logicalOperator: LogicalOperator.And,
        filterType: FilterType.Select,
    }),
    new TableColumn({
        name: ['TaskName'],
        label: 'Summary',
        filterOperator: QueryOperator.Contains,
        filterType: FilterType.Text,
    }),
    new TableColumn({
        name: ['Notes'],
        label: 'Customer Comment',
        filterOperator: QueryOperator.Contains,
        filterType: FilterType.Text,
    }),
    new TableColumn({
        name: ['Order.PaymentTypeID'],
        label: 'Payment typeID',
        filterOptions: FormHelper.createSelectOptions(PaymentType),
        logicalOperator: LogicalOperator.And,
        filterType: FilterType.Select,
    }),
    new TableColumn({
        name: ['paymentState'],
        label: 'Payment state',
        filterOptions: FormHelper.createSelectOptions(PaymentState),
        filterOperator: QueryOperator.Contains,
        filterType: FilterType.Select,
    }),
    new TableColumn({
        name: ['consultationType'],
        label: 'Consultation type',
        filterType: FilterType.Select,
        filterOptions: FormHelper.createSelectOptions(ConsultationType),
        filterOperator: QueryOperator.Contains,
    }),
    new TableColumn({
        name: ['PaperBagType'],
        label: 'Bag Type',
        filterType: FilterType.Select,
        filterOptions: FormHelper.createSelectOptions(BagType),
        filterOperator: QueryOperator.Contains,
    }),
    new TableColumn({
        name: ['Order.ProviderShop.ProviderShopName'],
        label: 'Original ProviderShop name',
        filterOperator: QueryOperator.Contains,
        filterType: FilterType.Text,
    }),
    new TableColumn({
        name: ['realProviderShopName'],
        label: 'Real ProviderShop name',
    }),
    new TableColumn({
        name: ['CreatedAt'],
        label: 'Creation Date',
        filterType: FilterType.DatePicker,
    }),
    new TableColumn({
        name: ['StateChangedAt'],
        label: 'State modified at',
        filterType: FilterType.DatePicker,
    }),
];
