import { Component, OnInit } from '@angular/core';
import { AppEnvironment, AppSettings, ColorClass } from 'shared';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OfflineProductsService } from '../../offline-products.service';
import { ModalService } from 'shared';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { CollectingItemsService } from '../../collecting-items.service';
import { CollectingStatesService } from '../../collecting-states.service';

@Component({
    selector: 'app-manual-sku-check-modal',
    templateUrl: './manual-sku-check-modal.component.html',
    styleUrls: ['./manual-sku-check-modal.component.scss'],
})
export class ManualSkuCheckModalComponent implements OnInit {
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    public ColorClass = ColorClass;
    public manualSKUCheckForm: UntypedFormGroup;
    public isSubmitted = false;
    public isRequestInProgress = false;
    public manualGTIN: string;

    public get fields() {
        return this.manualSKUCheckForm.controls;
    }

    public get canDebugOrder(): boolean {
        const env = AppSettings.getEnvironment();
        const invalidEnvs = [AppEnvironment.AtProduction, AppEnvironment.HuProduction];
        const debugMode = this._route.snapshot.queryParamMap.get('debugMode');
        return invalidEnvs.indexOf(env) < 0 && debugMode === '1';
    }

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _offlineProductsService: OfflineProductsService,
        private _collectingItemsService: CollectingItemsService,
        private _states: CollectingStatesService,
        private _modalService: ModalService,
        private _route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.initForm();
        this._modalService.isModalVisible
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.fields.sku.reset();
                this.isSubmitted = false;
            });
    }

    private initForm(): void {
        this.manualSKUCheckForm = this._formBuilder.group({
            sku: ['', [Validators.required]],
        });
    }

    public submit() {
        if (this.isRequestInProgress) {
            return;
        }

        this.isSubmitted = true;

        if (this.manualSKUCheckForm.invalid) {
            return;
        }

        this._collectingItemsService
            .checkIfOrderModificationIsBlocked(this._states.orderID)
            .subscribe((blocked) => {
                if (!blocked) {
                    this._offlineProductsService.getOfflineProduct(this.fields.sku.value);
                }
                this._modalService.setModalVisibility(false, 'manual-sku-check-modal');
            });
    }

    public onManualGTINSet(): void {
        this._collectingItemsService
            .checkIfOrderModificationIsBlocked(this._states.orderID)
            .subscribe((blocked) => {
                if (!blocked) {
                    this._offlineProductsService.getOfflineProduct(this.fields.sku.value);
                }
                this._modalService.setModalVisibility(false, 'manual-sku-check-modal');
            });
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
