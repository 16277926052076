import {
    Directive,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import {
    AUTH_SERVICE_IMPL,
    IAuthenticationService,
} from '../services/authentication/iauthentication.service';
import { Subject } from 'rxjs';
import { Policy } from '../types/policy';
import { UserRole } from '../types/user-role';

@Directive({
    selector: '[libCan]',
})
export class CanDirective implements OnInit, OnDestroy {
    @Input() libCan: Policy;

    stop$ = new Subject();
    isVisible = false;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService
    ) {}

    ngOnInit(): void {
        this._authService.applicationUser.subscribe((user) => {
            if (user === null) {
                return;
            }

            const userRoles = user.roles.map((role) => role.toLowerCase());
            const policyRole = this._authService
                .getPolicyRoles()
                .find((x) => x.policy === this.libCan);

            let policyRoles: string[] = [];

            if (policyRole !== undefined) {
                policyRoles = policyRole.roles.map((r) => UserRole[r].toLowerCase());
            }

            if (
                policyRole === undefined ||
                userRoles.every((role) => !policyRoles.includes(role))
            ) {
                this.isVisible = false;
                this.viewContainerRef.clear();
            } else if (!this.isVisible) {
                this.isVisible = true;
                this.viewContainerRef.createEmbeddedView(this.templateRef);
            }
        });
    }

    ngOnDestroy(): void {
        this.stop$.next(true);
    }
}
