import { Inject, Injectable } from '@angular/core';
import {
    FlashMessageService,
    IOrdersService,
    OrderItem,
    OrderItemState,
    OrderReturnItemDto,
    ORDERS_SERVICE_IMPL,
    prepareOrderItemList,
} from 'shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { IModifyOrderItem } from '../../../../../../../shared/src/lib/dtos/imodifyorderitemdto';

@Injectable({
    providedIn: 'root',
})
export class OrderItemListService {
    private _orderItems: BehaviorSubject<OrderItem[]>;
    private _isReturnInProgress: BehaviorSubject<boolean>;
    private _orderReturnItemList: BehaviorSubject<OrderReturnItemDto[]>;

    public totalPrice = 0;
    public totalCount = 0;
    public originalTotalWeight = 0;

    public get orderItems() {
        return this._orderItems.asObservable();
    }

    public get isReturnInProgress() {
        return this._isReturnInProgress.asObservable();
    }

    public get OrderReturnItemList() {
        return this._orderReturnItemList.asObservable();
    }

    constructor(
        @Inject(ORDERS_SERVICE_IMPL) private _ordersServie: IOrdersService,
        private _flashMessageService: FlashMessageService
    ) {
        this._orderItems = new BehaviorSubject<OrderItem[]>([]);
        this._isReturnInProgress = new BehaviorSubject<boolean>(false);
        this._orderReturnItemList = new BehaviorSubject<OrderReturnItemDto[]>([]);
    }

    public getOrderItems(taskID: string, orderID: number): void {
        this._ordersServie.getOrderItems(orderID).subscribe((orderItems) => {
            this._orderItems.next(prepareOrderItemList(orderItems));
            this.totalCount = this.getTotalCount();
            this.totalPrice = this.getTotalPrice();
        });
    }

    public getOriginalOrderWeight(orderID: number): void {
        this._ordersServie.getOriginalOrderWeight(orderID).subscribe((weight) => {
            this.originalTotalWeight = weight;
        });
    }

    public toggleOrderItemVisibility(orderItemID: number): void {
        const orderItems = this._orderItems.value.map((currentOrderItem) => {
            if (currentOrderItem.orderItemID === orderItemID) {
                currentOrderItem.isVisible = !currentOrderItem.isVisible;
            }
            return currentOrderItem;
        });
        this._orderItems.next([...orderItems]);
        this._isReturnInProgress.next(false);
        this._orderReturnItemList.next([]);
    }

    public setReturnInProgress(isInProgress: boolean): void {
        this._isReturnInProgress.next(isInProgress);
    }

    public openImage(src: string): void {
        var newTab = window.open();
        newTab.document.body.innerHTML = '<img src="' + src + '">';
    }

    private getTotalCount(): number {
        return this._orderItems
            .getValue()
            .filter(
                (x) =>
                    (x.quantity > 0 && x.isReady) ||
                    (x.state === OrderItemState.Replaced &&
                        x.orderReplacementItem !== null)
            ).length;
    }

    private getTotalPrice(): number {
        let totalPrice = 0;
        const validStates = [
            OrderItemState.Collected,
            OrderItemState.Approved,
            OrderItemState.Replaced,
        ];
        this._orderItems.getValue().forEach((orderItem) => {
            if (validStates.includes(orderItem.state)) {
                if (orderItem.orderReplacementItem !== null) {
                    totalPrice += orderItem.orderReplacementItem.price;

                    if (
                        orderItem.orderReplacementItem
                            .orderReplacementItemDepositProductList &&
                        orderItem.orderReplacementItem
                            .orderReplacementItemDepositProductList.length > 0
                    ) {
                        orderItem?.orderReplacementItem?.orderReplacementItemDepositProductList?.forEach(
                            (orderReplacementItemDepositProduct) => {
                                totalPrice +=
                                    orderReplacementItemDepositProduct.collectedQuantity *
                                    orderReplacementItemDepositProduct.price;
                            }
                        );
                    }
                } else {
                    totalPrice += orderItem.price;

                    if (
                        orderItem.orderItemDepositProductList &&
                        orderItem.orderItemDepositProductList.length > 0
                    ) {
                        orderItem?.orderItemDepositProductList.forEach(
                            (orderItemDepositProduct) => {
                                totalPrice +=
                                    orderItemDepositProduct.collectedQuantity *
                                    orderItemDepositProduct.price;
                            }
                        );
                    }
                }
            }
        });
        return totalPrice;
    }

  setOrderItem(selectedOrderItemForModify: IModifyOrderItem) : Observable<HttpResponse<void>> {
      return this._ordersServie.modifyOrderItem(selectedOrderItemForModify);
  }
}
