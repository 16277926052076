<table class="custom-event-editor" width="100%" cellpadding="5">
    <tbody>
        <tr>
            <td class="e-textlabel">{{ 'my-worktime.worktime-from' | translate }}</td>
            <td colspan="4">
                <input id="StartTime" class="e-field" type="text" name="StartTime" />
            </td>
        </tr>
        <tr>
            <td class="e-textlabel">{{ 'my-worktime.worktime-to' | translate }}</td>
            <td colspan="4">
                <input id="EndTime" class="e-field" type="text" name="EndTime" />
            </td>
        </tr>
        <tr>
            <td colspan="5"><hr /></td>
        </tr>
        <tr>
            <td class="e-textlabel">
                <strong>{{ 'my-worktime.minimum-shift-number' | translate }}</strong>
            </td>
            <td colspan="4">
                {{ minimumShiftNumber }} ({{ minimumShiftMinutes }}
                {{ 'my-worktime.minute' | translate }})
            </td>
        </tr>
        <tr>
            <td class="e-textlabel">
                <strong>
                    {{ 'my-worktime.maximum-shift-number' | translate }}
                </strong>
            </td>
            <td colspan="4">
                {{ maximumShiftNumber }} ({{ maximumShiftMinutes }}
                {{ 'my-worktime.minute' | translate }})
            </td>
        </tr>
        <tr>
            <td class="e-textlabel">
                <strong>
                    {{ 'my-worktime.shift-minutes' | translate }}
                </strong>
            </td>
            <td colspan="4">{{ shiftMinutes }} {{ 'my-worktime.minute' | translate }}</td>
        </tr>
        <tr>
            <td class="e-textlabel">
                <strong>
                    {{ 'my-worktime.break-shift-number' | translate }}
                </strong>
            </td>
            <td colspan="4">
                {{ breakShiftNumber }} ({{ breakShiftMinutes }}
                {{ 'my-worktime.minute' | translate }})
            </td>
        </tr>
    </tbody>
</table>
