<body>
    <div class="bootstrap-fs-modal">
        <ngx-pull-to-refresh
            spinnerColor="#33A4FF"
            spinnerSize="40px"
            distanceForRefresh="90"
            (refresh)="pullToRefreshEvent($event)"
        >
            <app-nav-menu></app-nav-menu>
        </ngx-pull-to-refresh>
        <lib-progress-line [classes]="'position-absolute'"></lib-progress-line>
        <div class="mt-3 p-3">
            <lib-flash-message></lib-flash-message>
            <router-outlet></router-outlet>
        </div>
    </div>
</body>
