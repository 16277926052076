import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'lib-page-size-dropdown',
    templateUrl: './page-size-dropdown.component.html',
    styleUrls: ['./page-size-dropdown.component.scss'],
})
export class PageSizeDropdownComponent {
    @Output() public onPageSizeChanged: EventEmitter<any> = new EventEmitter();
    public pageForm: UntypedFormGroup;
    public pageSizes: IPageSize[] = [
        { id: 5, name: 5 },
        { id: 10, name: 10 },
        { id: 20, name: 20 },
        { id: 50, name: 50 },
        { id: 100, name: 100 },
        { id: 200, name: 200 },
        { id: 500, name: 500 },
    ];
    public pageSize: IPageSize = { id: 50, name: 50 };

    constructor() {}

    public onPageSizeSelected(): void {
        this.onPageSizeChanged.emit(this.pageSize.id);
    }
}

export interface IPageSize {
    id: number;
    name: number;
}
