import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {
    ACCOUNTS_SERVICE_IMPL,
    AccountsService,
    AUTH_SERVICE_IMPL,
    FlashMessageService,
    IAccountsService,
    IAuthenticationService,
    ISelectOption,
    Role,
    UserRole,
} from 'shared';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RoleChooserService } from '../role-chooser/role-chooser.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdateUserRole } from 'projects/shared/src/lib/dtos/update-user-role';
import { ReplaySubject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { AssignUserToProvider } from 'projects/shared/src/lib/dtos/assign-user-to-provider';
import { User } from 'projects/shared/src/lib/models/user';
import { UsersService } from '../users.service';

@Component({
    selector: 'app-role-modal',
    templateUrl: './role-modal.component.html',
    styleUrls: ['./role-modal.component.scss'],
    providers: [
        {
            provide: ACCOUNTS_SERVICE_IMPL,
            useClass: AccountsService,
        },
    ],
})
export class RoleModalComponent implements OnInit, OnDestroy {
    public userRolesForm: UntypedFormGroup;
    public userRoles: Role[];
    public providers: ISelectOption[];
    public canSelectProvider: boolean;
    public selectedProvider: ISelectOption;
    private _selectedUser: User;
    private _currentProvider;
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    @ViewChild('roleChooserModal', { static: false })
    private _roleChooserModal: TemplateRef<any>;
    private _providerRelatedRoles: UserRole[] = [
        UserRole.Picker,
        UserRole.Deliverer,
        UserRole.ProviderAdmin,
        UserRole.AreaManager,
        UserRole.ProviderShopUser,
    ];

    constructor(
        @Inject(ACCOUNTS_SERVICE_IMPL) private _accountsService: IAccountsService,
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService,
        private _formBuilder: UntypedFormBuilder,
        private _roleChooserService: RoleChooserService,
        private _usersService: UsersService,
        private _flashMessage: FlashMessageService,
        private _modalService: NgbModal
    ) {
        this.userRolesForm = this._formBuilder.group({
            userRoles: new UntypedFormArray([]),
            provider: '',
        });
        this.userRoles = [];
        this.providers = [];
        this.selectedProvider = null;
    }

    get selectedProviderID(): number {
        return this.selectedProvider !== null ? parseInt(this.selectedProvider.id) : null;
    }

    get formData(): UntypedFormArray {
        return this.userRolesForm.get('Data') as UntypedFormArray;
    }

    ngOnInit(): void {
        this._roleChooserService.user
            .pipe(skip(1))
            .pipe(takeUntil(this.destroyed$))
            .subscribe((selectedUser) => {
                this._selectedUser = selectedUser;
                if (selectedUser.hasRoles(this._providerRelatedRoles)) {
                    this.canSelectProvider = true;
                    if (selectedUser.providerID != null) {
                        const currentProviderIndex = this.providers.findIndex(
                            (x) => x.id === selectedUser.providerID.toString()
                        );
                        if (currentProviderIndex > -1) {
                            this.selectedProvider = this.providers[currentProviderIndex];
                        }
                    } else {
                        this.selectedProvider = null;
                    }
                }
                this.showModal();
            });

        this._accountsService.getAssignRoleData().subscribe((response) => {
            this.userRoles = response.roles;
            this.providers = response.providers;
        });
    }

    public updateUserRoles(): void {
        const rolesData = new UpdateUserRole();
        rolesData.userID = this._selectedUser.userID;
        rolesData.providerID = this.selectedProviderID;
        rolesData.addToRoles = [];
        rolesData.removeFromRoles = [];

        (this.userRolesForm.controls.userRoles as UntypedFormArray).controls.map(
            (userRoleField, idx) => {
                const roleName = this.userRoles[idx].roleName;

                if (this._selectedUser.roles.includes(roleName) && !userRoleField.value) {
                    rolesData.removeFromRoles.push(roleName);
                } else if (
                    !this._selectedUser.roles.includes(roleName) &&
                    userRoleField.value
                ) {
                    rolesData.addToRoles.push(roleName);
                }
            }
        );

        if (rolesData.addToRoles.length || rolesData.removeFromRoles.length) {
            this._accountsService.setRoles(rolesData).subscribe((response) => {
                this._selectedUser.roles = this._selectedUser.roles.filter(
                    (role) => !rolesData.removeFromRoles.includes(role)
                );
                this._selectedUser.roles.push(...rolesData.addToRoles);
                this._selectedUser.providerID = this.selectedProviderID;
                this._usersService.updateUser(this._selectedUser);
            });
        } else if (this._selectedUser.providerID !== this.selectedProviderID) {
            this._selectedUser.providerID = this.selectedProviderID;
            this._usersService.updateUser(this._selectedUser);
            const userData = new AssignUserToProvider();
            userData.userID = this._selectedUser.userID;
            userData.providerID = this.selectedProviderID;
            this._accountsService.assignUserToProvider(userData).subscribe();
        }
        this._modalService.dismissAll();
    }

    public onRoleClicked(): void {
        let canSelectProvider = false;
        (this.userRolesForm.controls.userRoles as UntypedFormArray).controls.some(
            (role, idx) => {
                if (
                    this._providerRelatedRoles.some(
                        (allowedRole) =>
                            this.userRoles[idx].roleName ===
                            UserRole[allowedRole].toLowerCase()
                    ) &&
                    role.value
                ) {
                    canSelectProvider = true;
                    return true;
                }
            }
        );
        this.canSelectProvider = canSelectProvider;
    }

    private showModal() {
        if (this._selectedUser !== null && this.userRoles.length) {
            this.initCheckboxes();
            this._modalService.open(this._roleChooserModal, {
                windowClass: 'modal-holder',
                centered: true,
                size: 'md',
            });
        }
    }

    private initCheckboxes() {
        this.userRolesForm.controls.userRoles = new UntypedFormArray([]);
        this.userRoles.forEach((role: Role, i: number) => {
            const control = new UntypedFormControl(this.checkRole(role.roleName));
            (this.userRolesForm.controls.userRoles as UntypedFormArray).push(control);
        });
    }

    private checkRole(roleName: string) {
        return this._selectedUser.roles
            .map((role) => role.toLocaleLowerCase())
            .includes(roleName.toLocaleLowerCase());
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
        this._roleChooserService.setUser(null);
    }
}
