import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Supplier } from 'dm-src/models/supplier';
import {
    API_CLIENT_SERVICE_IMPL,
    AUTH_SERVICE_IMPL,
    ColorClass,
    FlashMessageService,
    FormHelper,
    IAPIClientService,
    IAuthenticationService,
    ISelectOption,
    ModalService,
    Policy,
    requiredIf,
    StreetType,
    User,
} from 'shared';
import {
    ISuppliersService,
    SUPPLIERS_SERVICE_IMPL,
} from 'dm-src/services/suppliers/isuppliers-service';
import { TranslateService } from '@ngx-translate/core';
import { AccountSupplierDataService } from 'dm-src/app/modules/my-account/account-supplier-data/account-supplier-data.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { skip } from 'rxjs/operators';
import { ResetSupplierAccount } from 'dm-src/dtos/reset-supplier-account';
import { SumUpUserData } from 'dm-src/models/sumup-user-data';
import { GetSupplierRevolutData } from 'dm-src/dtos/get-supplier-revolut-data';
import { SetSupplierRevolutBalance } from 'dm-src/dtos/set-supplier-revolut-balance';
import { IosAndAndroidNatvieFunctionsService } from 'dm-src/services/ios-and-android-natvie-functions/ios-and-android-natvie-functions.service';

@Component({
    selector: 'app-account-supplier-data',
    templateUrl: './account-supplier-data.component.html',
    styleUrls: ['./account-supplier-data.component.scss'],
})
export class AccountSupplierDataComponent implements OnInit {
    public isSubmitted = false;
    public isRequestInProgress = false;
    public isAccountResetInProgress = false;
    public canEditSuppliers = false;
    public canResetSupplierAccounts = false;
    public supplierDataForm: UntypedFormGroup;
    public vehicleTypes: ISelectOption[];
    public _streetTypes: BehaviorSubject<ISelectOption[]>;
    sumUpModalColorClass = ColorClass.Primary;
    sumUpBGModalColorClass = ColorClass.Secondary;
    sumUpSettingDataForm: UntypedFormGroup;
    public isSubmittedSumUp = false;
    currentRevolutBalance: number = 0;
    currentRevolutAccountId: string;
    isRevolutSaveError: boolean;
    revolutErrorText: string;

    isThisTesterUser = false; //We can remove this, after the app release
    showApplicationSettings = false;
    supplierIsExists: boolean;

    public get fields() {
        return this.supplierDataForm.controls;
    }

    public get sumUpfields() {
        return this.sumUpSettingDataForm.controls;
    }

    public get streetTypes(): Observable<ISelectOption[]> {
        return this._streetTypes.asObservable();
    }

    constructor(
        private _modalService: ModalService,
        private _formBuilder: UntypedFormBuilder,
        @Inject(SUPPLIERS_SERVICE_IMPL)
        private _suppliersService: ISuppliersService,
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService,
        @Inject(API_CLIENT_SERVICE_IMPL) private _apiClient: IAPIClientService,
        private _translateService: TranslateService,
        private _supplierDataService: AccountSupplierDataService,
        private _flashMessageService: FlashMessageService,
        private _iosAndAndroidNatvieFunctionsService: IosAndAndroidNatvieFunctionsService
    ) {
        this._streetTypes = new BehaviorSubject<ISelectOption[]>([]);
        this.supplierDataForm = this._formBuilder.group({
            phone: [null, Validators.required],
            vehicleTypeID: [null, Validators.required],
            accountID: [null, requiredIf(() => this.canEditSuppliers && this.supplierIsExists)],
            cardNumber: [null],
            sumUpApiClientID: [null],
            sumUpApiClientSecret: [null],
            sumUpApiUserName: [null],
            sumUpApiPassword: [null],
            efficiency: [
                100,
                [
                    requiredIf(() => this.canEditSuppliers),
                    Validators.min(1),
                    Validators.max(100),
                ],
            ],
            supplierRegionID: [null, requiredIf(() => this.canEditSuppliers)],
            startProviderShopID: [null, requiredIf(() => this.canEditSuppliers)],
        });

        this.sumUpSettingDataForm = this._formBuilder.group({
            sumUpApiUserName: [null, null],
            sumUpApiPassword: [
                null,
                Validators.required,
                Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,15}'),
            ],
            sumUpApiPasswordConfirm: [null, Validators.required],
        });
    }

    ngOnInit(): void {
        const currentUser = this._authService.currentUser;

        //We can remove this, after the app release
        if (currentUser.userID === 'a8613c68-a67b-4427-9f5b-3255986228ea')
            this.isThisTesterUser = true;

        this.setVisibilityRights(currentUser);
        this._supplierDataService.setSelectedSupplierID(currentUser.userID);
        this._streetTypes.next(this.getStreetTypeOptions());

        this.getVehicleTypeOptions();
        this.showApplicationSettings = window.ReactNativeWebView;
    }

    public onSupplierSelected(supplierID: string) {
        this._supplierDataService.setSelectedSupplierID(supplierID);
        this.isRevolutSaveError = false;
        this.isSubmitted = false;
    }

    public getSupplierData(supplierID: string) {
        this._suppliersService.getSupplierData(supplierID).subscribe((response) => {
            if (response.body.isExistingSupplier) {
                const data = response.body.supplier;
                this.supplierIsExists = true;
                this.supplierDataForm.patchValue({
                    ...data,
                    supplierRegionID: data.supplierRegionID || '',
                });
            } else {
              this.supplierIsExists = false;
            }
        });
    }

    public setSupplierRegion(supplierRegionID: string) {
        this.supplierDataForm.controls.supplierRegionID.setValue(supplierRegionID);
    }

    public onSubmit(): boolean {
        this.isSubmitted = true;

        if (this.supplierDataForm.invalid) {
            return false;
        }

        this.isRequestInProgress = true;
        this.storeSupplierData();
    }

    public storeSupplierData() {
        const supplierData: Supplier = {
            ...this.supplierDataForm.value,
            userID: this._supplierDataService.selectedSupplierID.getValue(),
        };

        this._suppliersService.updateSupplier(supplierData).subscribe(() => {
            this.isRequestInProgress = this.isSubmitted = false;
            this.supplierIsExists = true;
            this._flashMessageService.showMessage(
                null,
                'my-account.supplier-data-updated',
                3000,
                ColorClass.Success
            );
        });
    }

    public confirmAccountReset(): void {
        this.setAccountResetModalVisibility(true);
    }

    public confirmSetAccountBalance(): void {
        this.setSetAccountBalanceModalVisibility(true);
    }

    public setAccountResetModalVisibility(isVisible: boolean): void {
        this._modalService.setModalVisibility(
            isVisible,
            'confirm-supplier-account-reset'
        );
    }

    public setRevolutAccountBalance() {
        const requestBody = new SetSupplierRevolutBalance();
        requestBody.supplierID = this._supplierDataService.selectedSupplierID.getValue();
        requestBody.accountID = this.currentRevolutAccountId;
        requestBody.newBalance = this.currentRevolutBalance;

        this._suppliersService.setSupplierRevolutBalance(requestBody).subscribe(
            () => {
                this._modalService.setModalVisibility(false, 'supplier-revolut-account');
                this._supplierDataService.setSelectedSupplierID(requestBody.supplierID);
                this.isRevolutSaveError = false;
            },
            (error) => {
                this.isRevolutSaveError = true;
                this.revolutErrorText = error.error ? error.error : 'error.save';
            }
        );
    }

    public setSetAccountBalanceModalVisibility(isVisible: boolean): void {
        if (isVisible === true) {
            const requestBody = new GetSupplierRevolutData();
            requestBody.supplierId =
                this._supplierDataService.selectedSupplierID.getValue();
            this._suppliersService
                .getSupplierRevolutData(requestBody)
                .subscribe((response) => {
                    this.currentRevolutAccountId = response.body.accountId;
                    this.currentRevolutBalance = response.body.balance;
                });
        } else {
            this.currentRevolutBalance = 0;
            this.currentRevolutAccountId = '';
            this.isRevolutSaveError = false;
        }

        this._modalService.setModalVisibility(isVisible, 'supplier-revolut-account');
    }

    public setSumUpModalModalVisibility(isVisible: boolean): void {
        this.sumUpSettingDataForm.controls.sumUpApiUserName.setValue(
            this.supplierDataForm.controls['sumUpApiUserName'].value ?? ''
        );
        this._modalService.setModalVisibility(isVisible, 'sumup-modal');
    }

    public onAccountResetConfirmed(): void {
        const requestBody = new ResetSupplierAccount();
        this.isAccountResetInProgress = true;
        requestBody.supplierID = this._supplierDataService.selectedSupplierID.getValue();
        this._suppliersService.resetSupplierAccount(requestBody).subscribe((response) => {
            this.isAccountResetInProgress = false;
            this.setAccountResetModalVisibility(false);
            if (response.status === 200) {
                this._flashMessageService.showMessage(
                    this._translateService.instant('common.success'),
                    this._translateService.instant(
                        'my-account.supplier-account-reset-succeeded'
                    ),
                    3000,
                    ColorClass.Success
                );
            } else {
                this._flashMessageService.showStoredMessage();
            }
            this._modalService.setModalVisibility(
                false,
                'confirm-reset-supplier-account'
            );
        });
    }

    public getSelectedRegionID(): string {
        const regionID = this.fields.supplierRegionID.value;

        if (regionID !== null) {
            return regionID.toUpperCase();
        }
        return null;
    }

    public getStartProviderShopID(): string {
        const shopID = this.fields.startProviderShopID.value;

        if (shopID !== null) {
            return shopID.toString();
        }
        return null;
    }

    public setStartProviderShopID(providerShopID: string) {
        this.supplierDataForm.controls.startProviderShopID.setValue(providerShopID);
    }

    private setVisibilityRights(currentUser: User): void {
        this.canEditSuppliers = currentUser.can(Policy.EditSuppliers);
        this.canResetSupplierAccounts = currentUser.can(Policy.ResetSupplierAccounts);
        this._supplierDataService.currentSupplierID
            .pipe(skip(1))
            .subscribe((supplierID) => {
                this.getSupplierData(supplierID);
            });
    }

    private getVehicleTypeOptions() {
        this._apiClient
            .getWithResponse<any>(`vehicletypes/get-all`)
            .subscribe((data: any) => {
                this.vehicleTypes = data.body.map((vehicle) => {
                    return { id: vehicle.vehicleTypeID, label: vehicle.vehicleTypeName };
                });
            });
    }

    private getStreetTypeOptions(): ISelectOption[] {
        const selectOptions = FormHelper.createSelectOptions(StreetType, false);
        selectOptions.map(
            (option) =>
                (option.label = this._translateService.instant(
                    'street-type.' + option.label.toLowerCase()
                ))
        );
        return selectOptions;
    }

    inactivateMyUser(): void {
        const currentUser = this._authService.currentUser;
        this._supplierDataService.inactivateMyUser(currentUser.userID);
    }

    onSubmitSumupSettingDataForm(): boolean {
        this.isSubmittedSumUp = true;

        // confirm
        if (
            this.sumUpSettingDataForm.controls.sumUpApiPassword.value !==
            this.sumUpSettingDataForm.controls.sumUpApiPasswordConfirm.value
        ) {
            this.sumUpSettingDataForm.controls.sumUpApiPasswordConfirm.setErrors({
                nomatch: true,
            });
        }

        let pattern = '^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{8,}$';
        if (!this.sumUpSettingDataForm.controls.sumUpApiPassword.value.match(pattern)) {
            this.sumUpSettingDataForm.controls.sumUpApiPassword.setErrors({
                notvalid: true,
            });
        }

        if (this.sumUpSettingDataForm.invalid) {
            return false;
        }

        let formData: SumUpUserData = {
            userId: this._supplierDataService.selectedSupplierID.getValue(),
            password: this.sumUpSettingDataForm.controls.sumUpApiPassword.value,
        };

        this._suppliersService.setSumUpUserData(formData).subscribe((response) => {
            this.setAccountResetModalVisibility(false);
        });
    }

    showSettings(): void {
        this._iosAndAndroidNatvieFunctionsService.toggleSettings(true);
    }
}
