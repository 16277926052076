import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { OrderItem } from 'shared';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { CollectingItemsService } from 'dm-src/app/modules/collecting/collecting-items.service';
import { skip, takeUntil } from 'rxjs/operators';
import { environment } from 'dm-src/environments/environment';
import { PriceData } from 'dm-src/types/price-data';
import { ColorClass } from 'shared';
import { CollectingActionsService } from '../../collecting-actions.service';
import { CollectingStatesService } from '../../collecting-states.service';

@Component({
    selector: 'app-bill-approval-modal',
    templateUrl: './bill-approval-modal.component.html',
    styleUrls: ['./bill-approval-modal.component.scss'],
})
export class BillApprovalModalComponent implements OnInit, OnDestroy {
    private _destroy$: ReplaySubject<boolean>;
    public supportPhone: string;
    public billApprovalForm: UntypedFormGroup;
    public validatedPrice: PriceData;
    public calculatedPrice: PriceData;
    public isSubmitted: boolean;
    public ColorClass = ColorClass;
    public isRequestInProgress: boolean;
    @ViewChild('billApprovalModal', { static: false })
    private _billApprovalModal: TemplateRef<any>;

    public get fields() {
        return this.billApprovalForm.controls;
    }

    @ViewChild('collectApprovalModal', { static: false })
    private _collectApprovalModal: TemplateRef<any>;

    constructor(
        private _modalService: NgbModal,
        private _formBuilder: UntypedFormBuilder,
        private _states: CollectingStatesService,
        private _collectingItemsService: CollectingItemsService,
        private _actionsService: CollectingActionsService
    ) {
        this._destroy$ = new ReplaySubject<boolean>(1);
        this.isSubmitted = false;
        this.initApprovalForm();
        this.supportPhone = environment.supportPhone;
    }

    ngOnInit() {
        this.isRequestInProgress = false;
        this._states.isTotalPriceApprovalVisible
            .pipe(skip(1))
            .pipe(takeUntil(this._destroy$))
            .subscribe((isVisible) => {
                if (isVisible.valueOf()) {
                    this.validatedPrice = new PriceData();
                    this.calculatedPrice = new PriceData();

                    this.validateCalculatedTotal();

                    this._modalService.open(this._billApprovalModal, {
                        windowClass: 'modal-holder',
                        centered: true,
                        size: 'sm',
                    });
                } else {
                    this._modalService.dismissAll();
                }
            });
    }

    ngOnDestroy() {
        this._destroy$.next(true);
        this._destroy$.complete();
    }

    public onSubmit() {
        if (this.isRequestInProgress) {
            return;
        }

        this.isSubmitted = true;

        if (this.billApprovalForm.invalid) {
            return;
        }

        this.isRequestInProgress = true;

        this._actionsService.closeCollectingTask(parseInt(this.fields.totalPrice.value));
    }

    public closeModal() {
        this.isRequestInProgress = false;
        this._states.setTotalPriceApprovalModalVisible(false);
    }

    private initApprovalForm(): void {
        this.validatedPrice = new PriceData();
        this.billApprovalForm = this._formBuilder.group({
            totalPrice: ['', [Validators.required, this.totalPriceValidator()]],
        });
    }

    private validateCalculatedTotal(): void {
        const calculatedTotal = this._collectingItemsService.getCollectedTotalPrice();
        const calculatedThresholdAmount = environment.calculatedTotalPriceThresholdAmount;
        const calculatedThresholdPercentage =
            environment.calculatedTotalPriceThresholdPercentage;
        const originalTotalPrice = this._states.originalTotalPrice;

        this.calculatedPrice.isExceedingAmountThreshold =
            calculatedTotal > originalTotalPrice + calculatedThresholdAmount;
        this.calculatedPrice.isExceedingPercentageThreshold =
            calculatedTotal > (originalTotalPrice * calculatedThresholdPercentage) / 100;
        this.calculatedPrice.thresholdAmount = calculatedThresholdAmount;
        this.calculatedPrice.thresholdPercentage = calculatedThresholdPercentage;
        this.calculatedPrice.difference = calculatedTotal - originalTotalPrice;
    }

    private totalPriceValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const validatedTotal = control.value;
            const validatedThresholdAmount =
                environment.validatedTotalPriceThresholdAmount;
            const validatedThresholdPercentage =
                environment.validatedTotalPriceThresholdPercentage;
            const calculatedTotal = this._collectingItemsService.getCollectedTotalPrice();

            this.validatedPrice.isExceedingPercentageThreshold =
                calculatedTotal * (1 + validatedThresholdPercentage / 100) <
                validatedTotal;
            this.validatedPrice.isExceedingAmountThreshold =
                calculatedTotal + validatedThresholdAmount < validatedTotal;
            this.validatedPrice.thresholdAmount = validatedThresholdAmount;
            this.validatedPrice.thresholdPercentage = validatedThresholdPercentage;
            this.validatedPrice.difference = validatedTotal - calculatedTotal;

            return !this.validatedPrice.isInvalid ? null : { totalPrice: true };
        };
    }
}
