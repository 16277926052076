import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountsComponent } from './accounts.component';
import { RoleChooserComponent } from './role-chooser/role-chooser.component';
import { RoleModalComponent } from './role-modal/role-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { PreloaderModule } from 'dm-src/app/modules/preloader/preloader.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'shared';

@NgModule({
    declarations: [AccountsComponent, RoleChooserComponent, RoleModalComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgbModalModule,
        PreloaderModule,
        FormsModule,
        NgSelectModule,
        TranslateModule,
        ModalModule,
    ],
})
export class AccountsModule {}
