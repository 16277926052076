<div class="control-section">
    <app-worktime-settings></app-worktime-settings>
    <ejs-schedule
        #scheduler
        cssClass="schedule-cell-dimension"
        [eventSettings]="eventSettings"
        [timezone]="'Europe/Budapest'"
        [timeScale]="timeScale"
        (created)="onSchedulerCreated()"
        (popupOpen)="onTimeCellClicked($event)"
        (popupClose)="onPopupClosed($event)"
        (actionBegin)="onSchedulerAction($event)"
        (actionComplete)="onAfterSchedulerAction($event)"
    >
        <ng-template #editorTemplate>
            <app-resource-entry-editor
                [resourceSuppliers]="selectedResourceSuppliers"
            ></app-resource-entry-editor>
        </ng-template>
    </ejs-schedule>
</div>
