import { Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OrdersService, ORDERS_SERVICE_IMPL } from 'shared';
import { OrderDetails } from 'shared';
import { UpdateDeliveryFee } from 'shared';

@Component({
    selector: 'app-delivery-fee',
    templateUrl: './delivery-fee.component.html',
    styleUrls: ['./delivery-fee.component.scss'],
})
export class DeliveryFeeComponent implements OnInit {
    @Input() public orderDetails: OrderDetails;
    @Input() public orderID: number;

    public isEditMode = false;
    public isSubmitted = false;
    public isSuperOrder = false;
    public deliveryFeeForm: UntypedFormGroup;
    public updateDeliveryFeeDto: UpdateDeliveryFee;

    public get fields() {
        return this.deliveryFeeForm.controls;
    }

    constructor(
        private _formBuilder: UntypedFormBuilder,
        @Inject(ORDERS_SERVICE_IMPL) private _ordersService: OrdersService
    ) {}

    ngOnInit(): void {
        this.deliveryFeeForm = this._formBuilder.group({
            deliveryFee: [this.orderDetails.deliveryFee, Validators.required],
        });
      this.isSuperOrder = this.orderDetails.isSuperOrder;
    }

    public updateDeliveryFee() {
        this.isSubmitted = true;

        if (this.deliveryFeeForm.invalid) {
            return false;
        }

        this.updateDeliveryFeeDto = {
            orderID: this.orderID.toString(),
            deliveryFee: this.fields.deliveryFee.value,
        };

        this._ordersService
            .updateDeliveryFee(this.updateDeliveryFeeDto)
            .subscribe((response) => {
                if (response.status === 200) {
                    this.orderDetails.deliveryFee =
                        this.deliveryFeeForm.value.deliveryFee;
                    this.isEditMode = false;
                    //this.onchange.emit(this.deliveryFee);
                }
            });
    }
}
