import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-worktime-entry-editor',
    templateUrl: './worktime-entry-editor.component.html',
    styleUrls: ['./worktime-entry-editor.component.scss'],
})
export class WorktimeEntryEditorComponent implements OnInit {
    @Input() public maximumShiftNumber: number;
    @Input() public minimumShiftNumber: number;
    @Input() public shiftMinutes: number;
    @Input() public breakShiftNumber: number;

    public get breakShiftMinutes(): number {
        return this.breakShiftNumber * this.shiftMinutes;
    }

    public get maximumShiftMinutes(): number {
        return this.maximumShiftNumber * this.shiftMinutes;
    }

    public get minimumShiftMinutes(): number {
        return this.minimumShiftNumber * this.shiftMinutes;
    }

    constructor() {}

    ngOnInit(): void {}
}
