import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TourPlannerComponent } from './tour-planner.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbAccordionModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SUPPLIER_REGIONS_SERVICE_IMPL } from '../../../services/suppliers/isupplier-regions-service';
import { SupplierRegionsService } from '../../../services/suppliers/supplier-regions-service';

@NgModule({
    declarations: [TourPlannerComponent],
    providers: [
        {
            provide: SUPPLIER_REGIONS_SERVICE_IMPL, // That's the token we defined previously
            useClass: SupplierRegionsService, // That's the actual service itself
        },
    ],
    imports: [CommonModule, ReactiveFormsModule, NgbModule, NgbAccordionModule],
})
export class TourPlannerModule {}
