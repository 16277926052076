import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../../models/order';
import { OrderItem } from '../../models/order-item';
import { OrderItemState } from '../../types/order-item-state';
import { HttpResponse } from '@angular/common/http';
import { ICreateReplacementItem } from '../../dtos/i-create-replacement-item';
import { IUpdateOrderItem } from '../../dtos/i-update-order-item';
import { OrderListFilterOptions } from '../../types/order-list-filter-options';
import { ProviderOrderListFilterOptions } from '../../types/provider-order-list-filter-options';
import { CustomerContactData } from '../../models/customer-contact-data';
import { ICreateOrderItem } from '../../dtos/i-create-order-item';
import { OrderDetails } from '../../models/order-details';
import { OrderPaymentRefundResponse } from '../../types/order-payment-refund-response';
import { UpdateCustomerNotes } from '../../dtos/update-customer-notes';
import { UpdateDeliveryFee } from '../../dtos/update-delivery-fee';
import { MarkupCorrectionDto } from '../../dtos/markup-correction';
import { OrderHistory } from '../../models/order-history';
import { OrderRemovedItemReasons } from '../../types/order-removed-item-reasons';
import { CreateOrderRemovedItem } from '../../dtos/create-order-removed-item';
import { SupportCommentDto } from '../../dtos/support-comment.dto';
import { IModifyOrderItem } from '../../dtos/imodifyorderitemdto';
export const ORDERS_SERVICE_IMPL = new InjectionToken<IOrdersService>(
    'ordersServiceImpl'
);

export interface IOrdersService {
    getCountNotHandledReplacement(orderID: number): Observable<HttpResponse<number>>;
    getTimeLeftToModifyOrder(orderID: number): Observable<HttpResponse<Date>>;
    timeOutFinished(orderID: number): Observable<HttpResponse<any>>;
    collectFinished(orderID: number): Observable<HttpResponse<any>>;
    getOrder(orderID: number): Observable<HttpResponse<Order>>;
    getOrderItem(orderItemID: number): Observable<OrderItem>;
    getOrderItems(orderID: number): Observable<OrderItem[]>;
    getOrderByCode(orderCode: string): Observable<HttpResponse<Order>>;
    getOrderHistory(orderID: number): Observable<OrderHistory[]>;
    getOriginalOrderWeight(orderID: number): Observable<number>;
    getCustomerContactData(orderID: number): Observable<CustomerContactData>;
    getOrderInvoices(orderID: number): Observable<any>;
    getOrderDetails(orderID: number): Observable<OrderDetails>;
    getFilterOptions(): Observable<OrderListFilterOptions>;
    getProviderFilterOptions(): Observable<ProviderOrderListFilterOptions>;
    updateOrderItem(orderItemData: IUpdateOrderItem): Observable<HttpResponse<void>>;
    updateCustomerContactData(
        contactData: CustomerContactData
    ): Observable<HttpResponse<void>>;
    updateCustomerNotes(
        customerNotes: UpdateCustomerNotes
    ): Observable<HttpResponse<void>>;
    updateSupportComment(comment: SupportCommentDto): Observable<HttpResponse<void>>;
    createReplacementItem(
        replacementItemData: ICreateReplacementItem
    ): Observable<HttpResponse<OrderItem>>;
    updateReplacementItem(
        requestBody: ICreateReplacementItem
    ): Observable<HttpResponse<void>>;
    deleteReplacementItem(orderItemID: number): Observable<HttpResponse<void>>;
    createOrderItem(orderItemData: ICreateOrderItem): Observable<HttpResponse<OrderItem>>;
    setOrderItemState(
        orderItemID: number,
        orderItemState: OrderItemState
    ): Observable<HttpResponse<any>>;
    refundOrderPayment(
        orderID: number
    ): Observable<HttpResponse<OrderPaymentRefundResponse>>;
    getAvailableTimeSlots(orderID: number): Observable<HttpResponse<any>>; //TODO TimeSlotListDto
    changeEstimatedDeliveryEnd(
        orderID: number,
        estimatedDeliveryEnd: Date
    ): Observable<HttpResponse<Order>>;
    changeTimeSlotForDelivery(
        orderID: number,
        timeSLot: Date
    ): Observable<HttpResponse<Order>>;
    updateDeliveryFee(
        updateDeliveryFee: UpdateDeliveryFee
    ): Observable<HttpResponse<void>>;
    getOrderBlockedStatus(orderID: number): Observable<HttpResponse<boolean>>;
    correctStockForOrder(
        correctionDto: MarkupCorrectionDto
    ): Observable<HttpResponse<void>>;
    correctInvoiceForOrder(
        correctionDto: MarkupCorrectionDto
    ): Observable<HttpResponse<void>>;
    correctInvoiceAndStockForOrder(
        correctionDto: MarkupCorrectionDto
    ): Observable<HttpResponse<void>>;
    generateInvoiceJpl(orderID: number): Observable<HttpResponse<Blob>>;
    getOrderRemovedItemReasons(): Observable<OrderRemovedItemReasons[]>;
    createOrderRemovedItem(
        orderRemovedItemData: CreateOrderRemovedItem
    ): Observable<HttpResponse<void>>;
    deleteOrderRemovedItem(orderItemID: number): Observable<HttpResponse<void>>;
    setCustomerCalled(orderID: number): Observable<HttpResponse<void>>;
    modifyOrderItem(orderItem: IModifyOrderItem): Observable<HttpResponse<void>>;
}
