import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ISupplierResourcesService } from 'dm-src/services/suppliers/isupplier-resources-service';
import { SupplierResource } from 'dm-src/models/supplier-resource';
import { SupplierRegion } from 'dm-src/models/supplier-region';
import { Observable } from 'rxjs';
import { SchedulerResourceEvent } from 'dm-src/models/scheduler-resource-event';
import { SchedulerTimeslot } from 'dm-src/types/scheduler-timeslot';
import { SupplierWorktimeSettings } from 'dm-src/dtos/supplier-worktime-settings';
import { API_CLIENT_SERVICE_IMPL, IAPIClientService } from 'shared';

@Injectable()
export class SupplierResourcesService implements ISupplierResourcesService {
    constructor(@Inject(API_CLIENT_SERVICE_IMPL) private _apiClient: IAPIClientService) {}

    createSupplierResources(
        supplierResources: SupplierResource[]
    ): Observable<HttpResponse<string[]>> {
        return this._apiClient.postWithResponse<string[]>(
            `supplierResources`,
            supplierResources
        );
    }

    deleteSupplierResource(supplierResourceID: string): Observable<HttpResponse<void>> {
        return this._apiClient.deleteWithResponse<void>(
            `supplierResources/${supplierResourceID}`
        );
    }

    getSupplierResources(
        supplierRegionID: string
    ): Observable<HttpResponse<SchedulerResourceEvent[]>> {
        return this._apiClient.getWithResponse<SchedulerResourceEvent[]>(
            `supplierResources/${supplierRegionID}`
        );
    }

    updateSupplierResource(
        supplierResource: SupplierResource
    ): Observable<HttpResponse<void>> {
        return this._apiClient.putWithResponse<void>(
            `supplierResources`,
            supplierResource
        );
    }

    getAvailableTimeSlots(
        supplierID: string
    ): Observable<HttpResponse<SchedulerTimeslot[]>> {
        return this._apiClient.getWithResponse<SchedulerTimeslot[]>(
            `supplierResources/availableSlots/${supplierID}`
        );
    }

    getSupplierRegion(
        supplierID: string
    ): Observable<HttpResponse<SupplierRegion>> {
        return this._apiClient.getWithResponse<SupplierRegion>(
            `supplierResources/supplierRegion/${supplierID}`
        );
    }

    setWorktimeSettings(
        settings: SupplierWorktimeSettings
    ): Observable<HttpResponse<void>> {
        return this._apiClient.putWithResponse<void>(
            `supplierResources/worktimeSettings`,
            settings
        );
    }

    getWorktimeSettings(): Observable<HttpResponse<SupplierWorktimeSettings>> {
        return this._apiClient.getWithResponse<SupplierWorktimeSettings>(
            `supplierResources/worktimeSettings`
        );
    }
}
