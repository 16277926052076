import { Inject, Injectable, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { Dialog } from '@syncfusion/ej2-popups';
import { DateTimePicker, DateTimePickerModel } from '@syncfusion/ej2-calendars';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
    AgendaService,
    DayService,
    DragAndDropService,
    MonthService,
    ResizeService,
    WeekService,
    WorkWeekService,
    EventSettingsModel,
    PopupOpenEventArgs,
    ScheduleComponent,
    EJ2Instance,
    ActionEventArgs,
    TimeScaleModel,
    WorkHoursModel,
    EventClickArgs,
    ResizeEventArgs,
    DragEventArgs,
} from '@syncfusion/ej2-angular-schedule';
import { TranslateService } from '@ngx-translate/core';
import { SchedulerEvent } from 'dm-src/models/scheduler-event';
import { MyWorktimesService } from 'dm-src/app/modules/my-worktimes/my-worktimes.service';
import moment from 'moment';
import { ReplaySubject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { SchedulerTimeslot } from 'dm-src/types/scheduler-timeslot';
import { AUTH_SERVICE_IMPL, IAuthenticationService, Policy, UserRole } from 'shared';
import { HttpClient } from '@angular/common/http';
import { SchedulerBaseComponent } from 'dm-src/app/modules/shared/scheduler-base/scheduler-base.component';

@Component({
    selector: 'app-my-worktimes',
    templateUrl: './my-worktimes.component.html',
    styleUrls: ['./my-worktimes.component.scss'],
    providers: [
        DayService,
        WeekService,
        WorkWeekService,
        MonthService,
        AgendaService,
        ResizeService,
        DragAndDropService,
    ],
})

@Injectable({
    providedIn: 'root',
})
export class MyWorktimesComponent
    extends SchedulerBaseComponent
    implements OnInit, OnDestroy
{
    @ViewChild('scheduler', { static: true })
    public scheduler: ScheduleComponent;
    public Policy = Policy;
    private _timeslots: SchedulerTimeslot[];
    public views: Array<string> = ['Day', 'Week', 'WorkWeek', 'Month'];
    public currentSupplierID: string;
    public eventSettings: EventSettingsModel = {
        dataSource: [],
    };
    public eventBackup: SchedulerEvent;
    public workHours: WorkHoursModel = { start: '00:00', end: '00:00' };
    public skipApiCall: boolean;
    public timeScale: TimeScaleModel = {
        enable: true,
        interval: 15,
        slotCount: 1,
    };

    constructor(
        private _translateService: TranslateService,
        private snackBar: MatSnackBar,
        public myWorktimesService: MyWorktimesService,
        @Inject(AUTH_SERVICE_IMPL) private _authServices: IAuthenticationService,
        protected httpClient: HttpClient
    ) {
        super(httpClient);
    }

    public onTimeCellClicked(args: PopupOpenEventArgs): void {
        if(args?.target) {
            var hasAdminRole = this._authServices.currentUser.hasRoles([UserRole.Administrator]);
            var isDisabledCell = !args.target.classList.contains('e-work-hours');
            var isAppointmentCell = args.target.classList.contains('e-appointment');
    
            if (isDisabledCell &&
                !hasAdminRole &&
                !isAppointmentCell) {
                this.snackBar.open(
                    this._translateService.instant(
                        'messages.event-creation-refused'
                    ),
                    null,
                    {
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                        duration: 4000,
                    }
                );

                ((args.element as EJ2Instance).ej2_instances[0] as Dialog).hide();

                return;
            }
        }

        const dialogObj: Dialog = (args.element as EJ2Instance)
            .ej2_instances[0] as Dialog;
        if (args.type === 'QuickInfo' || args.type === 'EditEventInfo') {
            dialogObj.hide();
            const currentAction = args.target.classList.contains('e-work-cells')
                ? 'Add'
                : 'Save';
            this.scheduler.openEditor(args.data, currentAction);
        } else if (args.type === 'Editor') {
            const startElement: HTMLInputElement = args.element.querySelector(
                '#StartTime'
            ) as HTMLInputElement;
            const endElement: HTMLInputElement = args.element.querySelector(
                '#EndTime'
            ) as HTMLInputElement;

            const datePickerOptions: DateTimePickerModel = {
                format: 'yyyy.MM.dd. HH:mm',
                step: 60,
                strictMode: true,
            };

            if (!startElement.classList.contains('e-datetimepicker')) {
                datePickerOptions.value = new Date(startElement.value) || new Date();
                new DateTimePicker(datePickerOptions, startElement);
            }
            if (!endElement.classList.contains('e-datetimepicker')) {
                datePickerOptions.value = new Date(endElement.value) || new Date();
                new DateTimePicker(datePickerOptions, endElement);
            }
        }
    }

    public onEventClick(args: EventClickArgs): boolean {
        this.eventBackup = args.event as unknown as SchedulerEvent;
        return true;
    }

    public onBeforeEventResize(args: ResizeEventArgs): boolean {
        this.eventBackup = args.data as unknown as SchedulerEvent;
        return true;
    }

    public onBeforeEventMove(args: DragEventArgs): boolean {
        this.eventBackup = args.data as unknown as SchedulerEvent;
        return true;
    }

    public onSchedulerAction(args: ActionEventArgs): void {
        if (this.skipApiCall) {
            return;
        }

        let event: SchedulerEvent = null;

        const schedulerEvents = this.scheduler.eventSettings.dataSource as SchedulerEvent[];

        let validWorktime = false;

        switch (args.requestType) {
            case 'eventCreate':
                event = args.data[0] as SchedulerEvent;

                event.Subject = '';

                const validAvailableSlots = this.validateAvailableSlots(event);

                if (validAvailableSlots) {
                    validWorktime = this.myWorktimesService
                        .validateWorktime(
                            event,
                            schedulerEvents);
                }

                args.cancel = !validAvailableSlots || !validWorktime;

                break;
            case 'eventChange':
                event = args.data as SchedulerEvent;

                const validEventTime = this.validateEventTime(event);

                if (validEventTime) {
                    validWorktime = this.myWorktimesService
                        .validateWorktime(
                            event,
                            schedulerEvents);
                }

                args.cancel = !validEventTime || !validWorktime;

                break;
            case 'eventRemove':
                event = args.data[0] as SchedulerEvent;
                const isSchedulerReadonly = !this.validateEventTime(event);
                args.cancel =
                    !this.myWorktimesService.canUserRemoveEveryWorktime &&
                    isSchedulerReadonly;
                break;
        }
    }

    public onAfterSchedulerAction(args: ActionEventArgs) {
        if (this.skipApiCall) {
            this.skipApiCall = false;
            return;
        }

        let event: SchedulerEvent;

        if (args.data !== undefined) {
            event = args.data[0] as SchedulerEvent;
        }

        switch (args.requestType) {
            case 'viewNavigate':
            case 'dateNavigate':
                this.showAvailableTimeslots();
                break;
            case 'eventChanged':
                this.myWorktimesService
                    .updateSchedulerEvent(event)
                    .subscribe((response) => {
                        if (
                            response.status === 200 &&
                            !response.body.hasAvailableResourceTimeslots
                        ) {
                            this.skipApiCall = true;
                            this.scheduler.deleteEvent(event.Id);
                            this.skipApiCall = true;
                            this.scheduler.addEvent(this.eventBackup);
                            this.myWorktimesService.getAvailableTimeSlots();
                        }
                    });
                break;

            case 'eventCreated':
                this.myWorktimesService
                    .createSchedulerEvent(event)
                    .subscribe((response) => {
                        if (response.status === 200) {
                            this.skipApiCall = true;
                            if (response.body.hasAvailableResourceTimeslots) {
                                event.EventID = response.body.supplierWorktimeID;
                                this.scheduler.saveEvent(event);
                            } else {
                                this.scheduler.deleteEvent(event.Id);
                                this.myWorktimesService.getAvailableTimeSlots();
                            }
                        }
                    });
                break;
            case 'eventRemoved':
                this.myWorktimesService.deleteSchedulerEvent(event.EventID).subscribe();
                break;
        }
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    public onSchedulerCreated(): void {
        this.scheduler.firstDayOfWeek = 1;
        this.myWorktimesService.reset();
        this.myWorktimesService.availableTimeslots
            .pipe(skip(1))
            .pipe(takeUntil(this._destroy$))
            .subscribe((timeslots) => {
                if (timeslots.length) {
                    this._timeslots = timeslots;
                    this.showAvailableTimeslots();
                }
            });

        this.myWorktimesService.schedulerEvents
            .pipe(skip(1))
            .pipe(takeUntil(this._destroy$))
            .subscribe((events) => {
                this.skipApiCall = true;
                this.scheduler.deleteEvent(
                    this.scheduler.eventsData as { [key: string]: Object }[]
                );
                if (events.length) {
                    this.skipApiCall = true;
                    this.scheduler.addEvent(events);
                }
            });

        this.myWorktimesService.getAvailableTimeSlots();
        this.myWorktimesService.getSupplierRegion();
        this.myWorktimesService.getSechedulerEvents();
    }

    private validateEventTime(event: SchedulerEvent) {
        const isWorktimeEditable = this.validateAvailableSlots(this.eventBackup, false);
        if (isWorktimeEditable) {
            return this.validateAvailableSlots(event, true);
        }
        return false;
    }

    private validateAvailableSlots(
        event: SchedulerEvent,
        checkSameWorktime = true
    ): boolean {
        if (event.StartTime >= event.EndTime) {
            return false;
        }
        let worktimeStart = moment(event.StartTime).toDate();
        let worktimeEnd = moment(event.EndTime).toDate();

        let isValid = true;

        const schedulerEvents = this.scheduler.eventSettings
            .dataSource as SchedulerEvent[];

        let currentTime = worktimeStart;

        while (currentTime <= worktimeEnd) {
            if (
                !this.myWorktimesService.currentAvailableTimeslots.some(
                    (x) =>
                        x.slotStart.getTime() <= currentTime.getTime() &&
                        currentTime.getTime() <= x.slotEnd.getTime()
                ) ||
                (checkSameWorktime &&
                    schedulerEvents.some(
                        (x) =>
                            (moment.max([moment(x.StartTime), moment(worktimeStart)]) < 
                            moment.min([moment(x.EndTime), moment(worktimeEnd)])) &&
                            x.EventID !== event.EventID
                    ))
            ) {
                isValid = false;
                break;
            }

            currentTime = moment(currentTime).add(15, 'minutes').toDate();
        }

        return isValid;
    }

    private showAvailableTimeslots(): void {
        this._timeslots.forEach((timeslot) => {
            const { slotStart, slotEnd } = timeslot;

            const workday = new Date(
                slotStart.getFullYear(),
                slotStart.getMonth(),
                slotStart.getDate()
            );
            this.scheduler.setWorkHours(
                [workday],
                `${slotStart.getHours().toString()}:${slotStart.getMinutes().toString()}`,
                `${slotEnd.getHours().toString()}:${slotEnd.getMinutes().toString()}`
            );
        });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
