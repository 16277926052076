import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupplierTrackingComponent } from './supplier-tracking.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSelectModule } from 'ngx-select-ex';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { FormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AngularMultiSelectModule } from '@khajegan/angular2-multiselect-dropdown';

@NgModule({
    declarations: [SupplierTrackingComponent],
    imports: [
        CommonModule,
        TranslateModule,
        NgxSelectModule,
        FormsModule,
        NgMultiSelectDropDownModule,
        AngularMultiSelectModule,
        NgxSelectModule,
        NgxMapboxGLModule.withConfig({
            accessToken:
                'pk.eyJ1IjoiZ3Vib3dvcmsiLCJhIjoiY2t6NWVhYWVmMDFlMzJ2bnh0ZThuZjV3diJ9.HK6rIWWYycUUbo7vGz12zQ', // Optional, can also be set per map (accessToken input of mgl-map)
        }),
    ],
})
export class SupplierTrackingModule {}
